import {
  SpreadsheetProcessingStatus,
  useProcessingOrderStatus,
} from '../../hooks/use-processing-order-status'
import { StatusPartiallyProcessed } from '../status-partially-processed'
import { StatusProcessed } from '../status-processed'
import { StatusProcessing } from '../status-processing'

export type StatusType = {
  processedLines: number
  totalLines: number
  errorsFileUrl?: string
  percentageProgress?: number
}
export interface IProcessingStatusMap {
  [SpreadsheetProcessingStatus.PROCESSING]: React.FC<
    React.ComponentProps<typeof StatusProcessing>
  >
  [SpreadsheetProcessingStatus.PROCESSED]: React.FC<
    React.ComponentProps<typeof StatusProcessed>
  >
  [SpreadsheetProcessingStatus.PARTIALLY_PROCESSED]: React.FC<
    React.ComponentProps<typeof StatusPartiallyProcessed>
  >
}

const processingStatusMapMap: IProcessingStatusMap = {
  [SpreadsheetProcessingStatus.PROCESSING]: StatusProcessing,
  [SpreadsheetProcessingStatus.PROCESSED]: StatusProcessed,
  [SpreadsheetProcessingStatus.PARTIALLY_PROCESSED]: StatusPartiallyProcessed,
}

export function ProcessingStatus() {
  const {
    status,
    totalLines,
    processedLines,
    errorsFileUrl,
    percentageProgress,
  } = useProcessingOrderStatus()

  const ProcessingStatus = processingStatusMapMap[status]

  return (
    <ProcessingStatus
      totalLines={totalLines}
      processedLines={processedLines}
      errorsFileUrl={errorsFileUrl}
      percentageProgress={percentageProgress}
    />
  )
}
