import { useDebounce } from 'react-use'

import { useNewOrderStore } from '../../../store/use-new-order-store'
import { DEBOUNCE_SELECTED_DELAY } from '../config/constants'
import { Employee } from '../types'

export function useEmployeeSelectionSync(
  selectedData: Employee[],
  filteredEmployees: Employee[],
  selectedEmployeeIds: string[],
) {
  const { setSelectedEmployees, benefitType } = useNewOrderStore()

  useDebounce(
    () => {
      setSelectedEmployees(selectedData)
    },
    DEBOUNCE_SELECTED_DELAY,
    [benefitType, selectedData],
  )

  const calculateSelectedTotal = (
    activeFiltersCount: number,
    searchTerm: string,
  ) => {
    if (activeFiltersCount > 0 || !!searchTerm) {
      return filteredEmployees.filter(
        (employee) =>
          selectedEmployeeIds.includes(employee.id) ||
          selectedEmployeeIds.includes(employee.name),
      ).length
    }
    return selectedEmployeeIds.length
  }

  return {
    calculateSelectedTotal,
  }
}
