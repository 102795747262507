import { Divider, Tag, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useTranslation } from 'react-i18next'
import { Grid } from 'src/components/grid'
import { useTheme } from 'styled-components'

import { Benefits } from './components/benefits'
import { EditDays } from './components/edit-days'
import { TotalPartial } from './components/total-partial'
import { useOrderSummary } from './hooks/use-order-summary'
import { OrderSummaryContainerStyled } from './styles'

export function OrderSummary() {
  const { t } = useTranslation()
  const theme = useTheme()
  const { hasEmployeeSelected, groupedBenefits, total } = useOrderSummary()

  return (
    <OrderSummaryContainerStyled>
      <Grid.Container
        flexWrap="wrap"
        gap={theme.spacings.xs4}
        paddingX={theme.spacings.xs1}
        paddingY={theme.spacings.xs2}
      >
        <Typography
          variant="body3"
          variantColor={theme.colors.neutral20}
          weight={700}
        >
          {t('newOrder.orderSummary.title')}
        </Typography>
        {!!groupedBenefits.length && (
          <Tag variant="gray" size="extra-small" as="span">
            {t('order.checkout.orderSummary.itens', {
              count: groupedBenefits.length,
            })}
          </Tag>
        )}
      </Grid.Container>

      <Divider orientation="horizontal" />

      {hasEmployeeSelected ? (
        <>
          <Benefits benefits={groupedBenefits} />

          <Divider orientation="horizontal" />

          <TotalPartial amount={total} />

          <EditDays benefits={groupedBenefits} />
        </>
      ) : (
        <Grid.Container flexWrap="wrap" padding={theme.spacings.xs2}>
          <Typography variant="body3" variantColor={theme.colors.neutral30}>
            {t('newOrder.orderSummary.noSelection')}
          </Typography>
        </Grid.Container>
      )}
    </OrderSummaryContainerStyled>
  )
}
