import { Modal } from '@flash-tecnologia/flamingo-ui-modal'
import { Skeleton, Toggle } from '@flash-tecnologia/hros-web-ui-v2'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { BenefitEdit } from './components/benefit-edit'
import {
  EditAssignmentsModalProps,
  useEditAssignmentsModal,
} from './hooks/use-edit-assignments-modal-test'
import { BoxAdornmentStyled } from './styles'

const skeletonRows = Array.from({ length: 4 })

export function EditAssignmentsModal(props: EditAssignmentsModalProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const {
    hasUpdates,
    applyAsDefault,
    toggleApplyAsDefault,
    isLoadingBenefits,
    benefitsAssignments,
    handleConfirm,
    isLoadingUpdate,
    modalHeaders,
  } = useEditAssignmentsModal(props)

  return (
    <Modal.Root open size="medium" onOpenChange={props.onClose}>
      <Modal.Content.Root>
        <Modal.Content.Title>{modalHeaders.title}</Modal.Content.Title>
        <Modal.Content.Description>
          {modalHeaders.description}
        </Modal.Content.Description>

        {hasUpdates && (
          <BoxAdornmentStyled
            title={t(
              'newOrder.employeeSelection.simpleAssignmentEmployeeModal.adoptNewValues.title',
            )}
            description={t(
              'newOrder.employeeSelection.simpleAssignmentEmployeeModal.adoptNewValues.description',
            )}
            leftAdornment={
              <Toggle
                checked={applyAsDefault}
                onChange={toggleApplyAsDefault}
              />
            }
          />
        )}

        {isLoadingBenefits &&
          skeletonRows.map((_, index) => (
            <Skeleton
              style={{ marginBottom: theme.spacings.xs2 }}
              key={index}
              height={54}
              variant="rounded"
            />
          ))}

        {!isLoadingBenefits &&
          benefitsAssignments.map((benefit) => (
            <BenefitEdit key={benefit.id} benefit={benefit} />
          ))}

        <Modal.Content.Footer>
          <Modal.Content.Footer.CancelButton onClick={props.onClose}>
            Cancelar
          </Modal.Content.Footer.CancelButton>

          <Modal.Content.Footer.PrimaryButton
            onClick={handleConfirm}
            loading={isLoadingUpdate}
          >
            Alterar valores de benefícios
          </Modal.Content.Footer.PrimaryButton>
        </Modal.Content.Footer>
      </Modal.Content.Root>
    </Modal.Root>
  )
}
