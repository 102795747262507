import { Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { DefaultTheme } from '@mui/styled-engine-sc'
import type { GetOrderDepositsSummaryOutputType } from 'backend/src/routers/benefit-orders/dtos'
import { t, type TFunction } from 'i18next'
import { Box } from 'src/components/box'
import { cnpjMask, cpfMask } from 'src/utils/mask'

import { BoxStyled } from './styles'

type Cell = {
  row: {
    original: GetOrderDepositsSummaryOutputType['data'][0]
  }
}

const returnDefaultColumns = (
  t: TFunction,
  theme: DefaultTheme,
  company: { name: string; registrationNumber: string },
) => [
  {
    id: 'employeeName',
    header: () => (
      <Typography variant="body3" weight={700}>
        {t('modalEmployeesList.tableColumns.people')}
      </Typography>
    ),
    cell: ({ row }: Cell) => (
      <BoxStyled>
        <Typography
          weight={600}
          variant="body3"
          style={{ textTransform: 'capitalize' }}
        >
          {row.original.employeeName}
        </Typography>
        <Box display="flex" gap="xs5" alignItems="center">
          <Icons
            name="IconId"
            size={16}
            strokeWidth="2"
            color={theme.colors.neutral[60]}
          />
          <Typography variant="body4" color="neutral.60">
            {cpfMask(row.original.employeeDocument)}
          </Typography>
        </Box>
      </BoxStyled>
    ),
    enableSorting: false,
    sticky: 'left',
  },
  {
    id: 'companyName',
    header: () => (
      <Typography variant="body3" weight={700}>
        {t('modalEmployeesList.tableColumns.company')}
      </Typography>
    ),
    cell: () => (
      <BoxStyled>
        <Typography variant="body3" weight={600}>
          {company.name}
        </Typography>
        <Typography variant="body4" color="neutral.60">
          CNPJ {cnpjMask(company.registrationNumber)}
        </Typography>
      </BoxStyled>
    ),
  },
]

function returnBenefitColumns(apiResponse: GetOrderDepositsSummaryOutputType) {
  return apiResponse.meta.columns.map((benefit) => ({
    id: benefit,
    header: () => (
      <BoxStyled>
        <Typography variant="body3" weight={700}>
          {benefit}
        </Typography>
      </BoxStyled>
    ),
    cell: ({ row }: Cell) => {
      const deposit = row.original.deposits.find(
        (d) => d?.benefitName === benefit,
      )
      return (
        <Box display="flex" justifyContent="end">
          <Typography variant="body3" weight={600}>
            {t('modalEmployeesList.tableColumns.unitValue', {
              value: deposit?.amount ?? 0,
            })}
          </Typography>
        </Box>
      )
    },
  }))
}

export function mountColumns(
  t: TFunction,
  theme: DefaultTheme,
  company: { name: string; registrationNumber: string },
  orderDepositsSummaryLoading: boolean,
  apiResponse?: GetOrderDepositsSummaryOutputType,
) {
  if (orderDepositsSummaryLoading) {
    return []
  }

  if (!apiResponse) {
    return returnDefaultColumns(t, theme, company)
  }

  return [
    ...returnDefaultColumns(t, theme, company),
    ...returnBenefitColumns(apiResponse),
    {
      id: 'totalAmount',
      header: () => (
        <BoxStyled>
          <Typography variant="body3" weight={700}>
            {t('modalEmployeesList.tableColumns.amount')}
          </Typography>
        </BoxStyled>
      ),
      cell: ({ row }: Cell) => (
        <Box display="flex" justifyContent="end">
          <Typography variant="body3" weight={600}>
            {t('modalEmployeesList.tableColumns.unitValue', {
              value: row.original.totalAmount,
            })}
          </Typography>
        </Box>
      ),
      sticky: 'right',
    },
  ]
}
