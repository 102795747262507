import { BenefitTypeEnum } from 'src/enums/benefitTypeEnum'
import { create } from 'zustand'

import {
  Benefit,
  DepositTimeUnitEnum,
} from '../../../steps/employee-selection/types'

export enum EditAssignmentModeEnum {
  SINGLE_EMPLOYEE = 'single_employee',
  MULTIPLE_EMPLOYEES = 'multiple_employees',
  UNASSIGNED_EMPLOYEES = 'unassigned_employees',
}

export type BenefitAssignment = {
  id: string
  value: number
  name: string
  emoji: string
  depositTimeUnit: DepositTimeUnitEnum
  topupDepositEnabled: boolean
  benefitType: BenefitTypeEnum
}

type EditAssignmentsModalGetters = {
  mode: EditAssignmentModeEnum
  updatedBenefits: Benefit[]
}

type EditAssignmentsModalSetters = {
  setMode: (mode: EditAssignmentModeEnum) => void
  updateBenefit: (
    benefitAssignment: BenefitAssignment,
    value: number | null,
  ) => void
  reset: () => void
}

type EditAssignmentsModalStore = EditAssignmentsModalGetters &
  EditAssignmentsModalSetters

const initialStore: EditAssignmentsModalGetters = {
  mode: EditAssignmentModeEnum.SINGLE_EMPLOYEE,
  updatedBenefits: [],
}

export const useEditAssignmentsModalStore = create<EditAssignmentsModalStore>(
  (set, get) => ({
    ...initialStore,
    setMode: (mode) => {
      set({ mode })
    },
    updateBenefit: (benefitAssignment, value) => {
      const { updatedBenefits } = get()

      if (value === null) {
        const newUpdatedBenefits = updatedBenefits.filter(
          (benefit) => benefit.id !== benefitAssignment.id,
        )

        return set({ updatedBenefits: newUpdatedBenefits })
      }

      const existingIndex = updatedBenefits.findIndex(
        (benefit) => benefit.id === benefitAssignment.id,
      )

      if (existingIndex !== -1) {
        const updated = [...updatedBenefits]

        updated[existingIndex] = { ...updated[existingIndex], value }

        return set({ updatedBenefits: updated })
      }

      set({
        updatedBenefits: [
          ...updatedBenefits,
          {
            id: benefitAssignment.id,
            name: benefitAssignment.name,
            value,
            url: benefitAssignment.emoji,
            depositTimeUnit:
              benefitAssignment.depositTimeUnit as DepositTimeUnitEnum,
            topupDepositEnabled: benefitAssignment.topupDepositEnabled,
            benefitType: benefitAssignment.benefitType,
          },
        ],
      })
    },
    reset: () => {
      set({ ...initialStore })
    },
  }),
)
