import { useCallback } from 'react'
import { BenefitTypeEnum } from 'src/enums/benefitTypeEnum'
import { useToast } from 'src/utils/hooks/useToast'

import { useNewOrderStore } from '../../../store/use-new-order-store'
import { buildDeposits } from '../../payment-setup/utils/build-deposits'

const MINIMUM_DEPOSIT_AMOUNT = 200

export function useEmployeeActions() {
  const { toastWarning, toastError } = useToast()
  const {
    benefitType,
    creditDays,
    nextStep,
    setFilterEmployees,
    selectedEmployees,
  } = useNewOrderStore()

  const handleEditGroup = useCallback(() => {
    window.open('/settings/groups', '_blank')
  }, [])

  const handleContinue = useCallback(() => {
    if (!selectedEmployees.length) {
      toastWarning({
        title:
          'É necessário selecionar pessoas para prosseguir com seu pedido.',
      })
      return
    }

    const employeesWithoutBenefits = selectedEmployees.every(
      (employee) => !employee.benefits?.length,
    )

    if (employeesWithoutBenefits) {
      toastWarning({
        title: `É necessário selecionar pessoas com ${benefitType === BenefitTypeEnum.PLASTIC ? 'benefício no cartão' : 'benefício virtual'} disponível para prosseguir com seu pedido.`,
      })
      return
    }

    const hasLowDepositAmount = buildDeposits(
      selectedEmployees,
      creditDays,
    ).some(
      (deposit) =>
        deposit.amount > 0 && deposit.amount < MINIMUM_DEPOSIT_AMOUNT,
    )

    if (hasLowDepositAmount) {
      toastError({
        title: 'Não é possível realizar depósitos com valor abaixo de R$ 2,00',
        description:
          'Uma ou mais pessoas estão com valores de benefícios abaixo de R$ 2,00 atribuídos a elas. Por favor, remova as pessoas do pedido ou altere o valor de benefício delas.',
      })
      return
    }

    setFilterEmployees({ groups: [] })
    nextStep()
  }, [
    benefitType,
    creditDays,
    nextStep,
    selectedEmployees,
    setFilterEmployees,
    toastError,
    toastWarning,
  ])

  return {
    handleEditGroup,
    handleContinue,
  }
}
