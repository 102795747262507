import { legacyGetFromLS } from '@flash-tecnologia/hros-web-utility'

type GetClientDataFromLSResponse = {
  adminId: string
  companyId: string
}

export async function getClientDataFromLS(): Promise<GetClientDataFromLSResponse> {
  return new Promise((resolve) => {
    const checkClientData = () => {
      const clientData = legacyGetFromLS('clientData')

      if (clientData && clientData.companyId && clientData.adminId) {
        resolve({
          adminId: clientData.adminId,
          companyId: clientData.companyId,
        })
      } else {
        setTimeout(checkClientData, 100)
      }
    }

    checkClientData()
  })
}
