import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { Box } from 'src/components/box'

import { ProcessingStatus } from './components/processing-status'
import { GridContainerStyled, TitleStyled } from './styles'

export function ProcessingStatusOrder() {
  return (
    <>
      <TitleStyled variant="headline6" weight={700} color="neutral.20">
        Pedido de benefício por planilha com modelo Flash
      </TitleStyled>
      <GridContainerStyled>
        <Box display="flex" flexDirection="column">
          <TitleStyled variant="headline7" weight={700} color="neutral.20">
            Status da importação do pedido de benefícios
          </TitleStyled>
          <Box gap="xs">
            <Typography variant="body3" weight={400} color="neutral.40">
              Acompanhe aqui o status da sua importação do pedido de benefícios.
              Esse processo pode demorar alguns minutos, mas você pode fechar
              essa página e continuar usando a plataforma normalmente!
            </Typography>
          </Box>
        </Box>
        <Box.Bordered padding="m">
          <ProcessingStatus />
        </Box.Bordered>
      </GridContainerStyled>
    </>
  )
}
