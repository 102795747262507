import {
  Badge,
  Button,
  Icons,
  PillButton,
  Table,
  Typography,
  Unstable,
} from '@flash-tecnologia/hros-web-ui-v2'
import { useMemo } from 'react'
import { RadioCard } from 'src/components/radio-card'
import { RadioCardTextTypeEnum } from 'src/components/radio-card/radio-card-text/radio-card-text'

import { EditAssignmentsModal } from '../../components/edit-assignments-modal'
import { EditAssignmentModeEnum } from '../../components/edit-assignments-modal/store/use-edit-assignments-modal-store'
import { OrderSummary } from '../../components/order-summary'
import { Filters } from './components/filters'
import { EmployeeSelectionProvider } from './context'
import { useEmployeeSelection } from './hooks/use-employee-selection'
import {
  ContentContainerStyled,
  EmployeeSelectionContainerStyled,
  HeaderContainerStyled,
  RadioCardsContainerStyled,
  RadioCardsGridStyled,
} from './styles'

function EmployeeSelectionContent() {
  const {
    employees,
    employeeTotal,
    pagination,
    tableHeaderGroups,
    setPagination,
    handleSearch,
    employeeSelectedTotal,
    clearSelectedRows,
    selectedAllEmployees,
    isBulkUpdateModalOpen,
    closeBulkUpdateModal,
    openBulkUpdateModal,
    selectedEmployees,
    isLoading,
    isFilterDrawerOpen,
    closeFilterDrawer,
    openFilterDrawer,
    activeFiltersCount,
    redirectToEmployees,
    noEmployeesData,
    setBenefitTypePlastic,
    setBenefitTypeVirtual,
    isBenefitTypePlastic,
    isBenefitTypeVirtual,
  } = useEmployeeSelection()

  const renderEmpty = useMemo(() => {
    const message = noEmployeesData
      ? 'Nenhuma pessoa cadastrada'
      : 'Nenhum resultado encontrado'
    const isFiltered = !noEmployeesData
    const children = noEmployeesData ? (
      <Button variant="primary" onClick={redirectToEmployees}>
        Cadastrar pessoas <Icons name="IconArrowNarrowRight" />
      </Button>
    ) : null

    return {
      message,
      isFiltered,
      children,
    }
  }, [noEmployeesData, redirectToEmployees])

  return (
    <EmployeeSelectionContainerStyled>
      <ContentContainerStyled>
        <RadioCardsContainerStyled>
          <HeaderContainerStyled>
            <Typography variant="headline8" weight={700} color="neutral.20">
              Selecione o tipo de benefício
            </Typography>

            <Typography variant="body3" color="neutral.40">
              Só é possível realizar o pedido de um tipo de benefício por vez
            </Typography>
          </HeaderContainerStyled>

          <RadioCardsGridStyled>
            <RadioCard.Root
              checked={isBenefitTypePlastic}
              disabled={false}
              onClick={setBenefitTypePlastic}
            >
              <RadioCard.Radio
                checked={isBenefitTypePlastic}
                disabled={false}
              />

              <div>
                <RadioCard.Text
                  checked={isBenefitTypePlastic}
                  disabled={false}
                  text="Benefícios no cartão"
                  textVariant="body4"
                  type={RadioCardTextTypeEnum.TITLE}
                />

                <RadioCard.Text
                  checked={isBenefitTypePlastic}
                  disabled={false}
                  text="Selecione para fazer pedido de benefícios que são utilizados no cartão"
                  textVariant="caption"
                  type={RadioCardTextTypeEnum.DESCRIPTION}
                />
              </div>
            </RadioCard.Root>

            <RadioCard.Root
              checked={isBenefitTypeVirtual}
              disabled={false}
              onClick={setBenefitTypeVirtual}
            >
              <RadioCard.Radio
                checked={isBenefitTypeVirtual}
                disabled={false}
              />

              <div>
                <RadioCard.Text
                  checked={isBenefitTypeVirtual}
                  disabled={false}
                  text="Benefícios virtuais"
                  textVariant="body4"
                  type={RadioCardTextTypeEnum.TITLE}
                />

                <RadioCard.Text
                  checked={isBenefitTypeVirtual}
                  disabled={false}
                  text="Selecione para fazer pedido de benefícios que são utilizados de formas virtuais"
                  textVariant="caption"
                  type={RadioCardTextTypeEnum.DESCRIPTION}
                />
              </div>
            </RadioCard.Root>
          </RadioCardsGridStyled>
        </RadioCardsContainerStyled>

        <HeaderContainerStyled hasMarginBottom>
          <Typography variant="headline8" weight={700} color="neutral.20">
            Selecione as pessoas que farão parte do seu pedido
          </Typography>

          <Typography variant="body3" color="neutral.40">
            Confira sempre o resumo do pedido para acompanhar as suas escolhas
          </Typography>
        </HeaderContainerStyled>

        <Table.Root variant="soft">
          <Table.Content>
            <Table.FilterSearch
              labelSearch="Buscar por nome ou CPF"
              onSearch={(event) => handleSearch(event.target.value)}
            >
              <Badge
                color="neutral"
                variant="primary"
                content={activeFiltersCount}
              >
                <PillButton
                  icon="IconFilter"
                  label="Filtros"
                  onClick={openFilterDrawer}
                  variant="default"
                  size="small"
                  type="primary"
                  disabled={isLoading || noEmployeesData}
                />
              </Badge>
            </Table.FilterSearch>

            <Table.Grid.Root loading={isLoading} empty={renderEmpty}>
              <Table.Grid.Header getHeaderGroups={tableHeaderGroups} />

              {employees.map((employee) => (
                <Table.Grid.Row key={employee.id} row={employee} />
              ))}
            </Table.Grid.Root>

            <Table.BulkActions
              open={employeeSelectedTotal > 0}
              total={employeeTotal}
              totalSelected={employeeSelectedTotal}
              onSelectAll={selectedAllEmployees}
              onClearSelection={clearSelectedRows}
            >
              <PillButton
                label="Editar valores"
                icon="IconEdit"
                size="extra-small"
                variant="pink"
                iconPosition="left"
                onClick={openBulkUpdateModal}
              />
            </Table.BulkActions>

            <Table.Pagination
              count={employeeTotal}
              onPaginationChange={setPagination}
              pagination={pagination}
              showItemRange
            />
          </Table.Content>
        </Table.Root>
      </ContentContainerStyled>

      <OrderSummary />

      {isBulkUpdateModalOpen && (
        <EditAssignmentsModal
          onClose={closeBulkUpdateModal}
          mode={EditAssignmentModeEnum.MULTIPLE_EMPLOYEES}
          employees={selectedEmployees}
        />
      )}

      <Unstable.Drawer
        title="Filtros"
        open={isFilterDrawerOpen}
        onClose={closeFilterDrawer}
      >
        <Filters onCloseFilter={closeFilterDrawer} />
      </Unstable.Drawer>
    </EmployeeSelectionContainerStyled>
  )
}

export function EmployeeSelection() {
  return (
    <EmployeeSelectionProvider>
      <EmployeeSelectionContent />
    </EmployeeSelectionProvider>
  )
}
