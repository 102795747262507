import { IconTypes } from '@flash-tecnologia/hros-web-ui-v2'
import { processEnv } from 'src/utils/env'
import { create } from 'zustand'

export enum Step {
  FLASH_SPREADSHEET = 0,
  ADD_EMPLOYEES = 1,
  PROCESSING_ORDER = 2,
}

export type StepNavigationButtonOptions = {
  text: string
  onClick?: () => void
  isLoading?: boolean
  showIcon?: boolean
  iconName?: IconTypes
  iconPosition?: 'left' | 'right'
  disabled?: boolean
}

type OrderBySpreadsheetStoreState = {
  fileId: string | null
  uploadUrl: string | null
  currentStep: Step
  droppedFile: File | null
  backButtonOptions: StepNavigationButtonOptions | null
  continueButtonOptions: StepNavigationButtonOptions | null
}

type NewOrderStoreSetters = {
  setFileId: (fileId: string | null) => void
  setUploadUrl: (fileId: string | null) => void
  setDroppedFile: (file: File | null) => void
  setContinueButtonOptions: (
    options: StepNavigationButtonOptions | null,
  ) => void
  setBackButtonOptions: (options: StepNavigationButtonOptions | null) => void
  resetStore: () => void
  nextStep: (isOnlyOrder?: boolean) => void
  previousStep: () => void
}

type NewOrderStore = OrderBySpreadsheetStoreState & NewOrderStoreSetters

const initialState: OrderBySpreadsheetStoreState = {
  currentStep: Step.FLASH_SPREADSHEET,
  droppedFile: null,
  continueButtonOptions: null,
  backButtonOptions: null,
  fileId: null,
  uploadUrl: null,
}

export const useOrderBySpreadsheetStore = create<NewOrderStore>((set) => ({
  ...initialState,
  setDroppedFile: (droppedFile) => {
    set({ droppedFile })
  },
  setFileId: (fileId) => {
    set({ fileId })
  },
  setUploadUrl: (uploadUrl) => {
    set({ uploadUrl })
  },
  setContinueButtonOptions: (options) => {
    set({ continueButtonOptions: options })
  },
  setBackButtonOptions: (options) => {
    set({ backButtonOptions: options })
  },
  nextStep: (isOnlyOrder) => {
    const stepMapping = {
      [Step.FLASH_SPREADSHEET]: isOnlyOrder
        ? Step.PROCESSING_ORDER
        : Step.ADD_EMPLOYEES,
      [Step.ADD_EMPLOYEES]: Step.PROCESSING_ORDER,
      [Step.PROCESSING_ORDER]: Step.PROCESSING_ORDER,
    }

    set((oldState) => ({
      currentStep: stepMapping[oldState.currentStep],
      continueButtonOptions: null,
      backButtonOptions: null,
    }))
  },
  previousStep: () => {
    const stepMapping = {
      [Step.FLASH_SPREADSHEET]: Step.FLASH_SPREADSHEET,
      [Step.ADD_EMPLOYEES]: Step.FLASH_SPREADSHEET,
      [Step.PROCESSING_ORDER]: Step.ADD_EMPLOYEES,
    }

    set((oldState) => ({
      currentStep: stepMapping[oldState.currentStep],
      continueButtonOptions: null,
      backButtonOptions: null,
    }))
  },
  resetStore: () => {
    set({ ...initialState })
  },
}))

if (processEnv.BUILD_ENV === 'development') {
  useOrderBySpreadsheetStore.subscribe((state) => {
    console.log('state updated (useOrderBySpreadsheetStore)', state)
  })
}
