import { Divider, Tag, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useTranslation } from 'react-i18next'
import { Grid } from 'src/components/grid'
import { BenefitTypeEnum } from 'src/enums/benefitTypeEnum'
import { useTheme } from 'styled-components'

import { Benefits } from './components/benefits'
import { EditDays } from './components/edit-days'
import { TotalPartial } from './components/total-partial'
import { UnassignEmployeesCard } from './components/unassign-employees-card'
import { useOrderSummary } from './hooks/use-order-summary'
import { OrderSummaryContainerStyled } from './styles'

export function OrderSummary() {
  const { t } = useTranslation()
  const theme = useTheme()
  const {
    employeesWithoutAssign,
    groupedBenefits,
    handleRemoveUnassignEmployees,
    hasEmployeeSelected,
    total,
    selectedEmployeesCount,
    benefitType,
  } = useOrderSummary()

  return (
    <div>
      <OrderSummaryContainerStyled>
        <Grid.Container
          flexWrap="wrap"
          gap={theme.spacings.xs4}
          paddingX={theme.spacings.xs1}
          paddingY={theme.spacings.xs2}
        >
          <Typography variant="body3" color="neutral.20" weight={700}>
            {t('newOrder.orderSummary.title')}
          </Typography>
          {!!groupedBenefits.length && (
            <Tag variant="gray" size="extra-small" as="span">
              {t('order.checkout.orderSummary.itens', {
                count: groupedBenefits.length ?? 0,
              })}
            </Tag>
          )}
        </Grid.Container>

        <Divider orientation="horizontal" />

        {!hasEmployeeSelected && (
          <Grid.Container flexWrap="wrap" padding={theme.spacings.xs2}>
            <Typography variant="body3" color="neutral.30">
              Quando começar a selecionar as pessoas da empresa poderá
              acompanhar uma prévia do seu pedido por aqui.
            </Typography>
          </Grid.Container>
        )}

        {hasEmployeeSelected && groupedBenefits.length === 0 && (
          <Grid.Container flexWrap="wrap" padding={theme.spacings.xs2}>
            <Typography variant="body3" color="neutral.30">
              {selectedEmployeesCount === 1
                ? `A pessoa selecionada não possui benefício ${benefitType === BenefitTypeEnum.VIRTUAL ? 'virtual' : 'no cartão'} atribuído.`
                : `As pessoas selecionadas não possuem benefício ${benefitType === BenefitTypeEnum.VIRTUAL ? 'virtual' : 'no cartão'} atribuído.`}
            </Typography>
          </Grid.Container>
        )}

        {hasEmployeeSelected && groupedBenefits.length > 0 && (
          <>
            <Benefits benefitType={benefitType} benefits={groupedBenefits} />

            <Divider orientation="horizontal" />

            <TotalPartial amount={total} />

            <EditDays benefits={groupedBenefits} />
          </>
        )}
      </OrderSummaryContainerStyled>

      {!!employeesWithoutAssign.length && (
        <UnassignEmployeesCard
          employeesWithoutAssign={employeesWithoutAssign}
          onClick={handleRemoveUnassignEmployees}
        />
      )}
    </div>
  )
}
