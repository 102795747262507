import {
  HeaderGroup,
  Row,
} from '@flash-tecnologia/hros-web-ui-v2/dist/components/Table/shared/table.types'
import {
  createContext,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'react-use'
import { trpc } from 'src/api/client'
import { useRowAndSubRowSelection } from 'src/utils/hooks'
import { Pagination } from 'src/utils/hooks/usePagination'
import { useToast } from 'src/utils/hooks/useToast'

import { useNewOrderPage } from '../../hooks/use-new-order-page'
import { useNewOrderStore } from '../../store/use-new-order-store'
import {
  DEBOUNCE_SEARCH_PAGINATION_DELAY,
  DEBOUNCE_SELECTED_DELAY,
} from './config/constants'
import { useTableConfig } from './hooks/use-table-config'
import { Employee, Group } from './types'
import { getTotalEmployee } from './utils/get-total-employee'
import { searchEmployeeInGroups } from './utils/search-employee'

type EmployeeSelectionContextType = {
  groups: Row<Group>[]
  total: number
  totalEmployee: number
  selectedEmployees: string[]
  areAllSelected: boolean
  isHeaderIndeterminate: boolean
  pagination: Pagination
  isLoading: boolean
  visibleTableRef: RefObject<HTMLDivElement>
  tableHeaderGroups: () => HeaderGroup<Group>[]
  toggleSelectAll: () => void
  setPagination: (pagination: Pagination) => void
  setSearchTerm: (searchTerm: string) => void
  handleEditGroup: () => void
  toggleEmployeeSelection: (employeeId: string, groupId: string) => void
  getVisibleTableRowHeight: () => number
  tableColumnWidths: number[]
}

type EmployeeSelectionProps = {
  children: ReactNode
}

export const EmployeeSelectionContext = createContext(
  {} as EmployeeSelectionContextType,
)

export function EmployeeSelectionProvider({
  children,
}: EmployeeSelectionProps) {
  const [searchTerm, setSearchTerm] = useState('')
  const [tableColumnWidths, setTableColumnWidths] = useState<number[]>([])
  const {
    selectedEmployees: selectedDefault,
    setSelectedEmployees,
    setContinueButtonOptions,
    nextStep,
  } = useNewOrderStore()

  const { setSearchParams } = useNewOrderPage()

  const { t } = useTranslation()
  const { toastWarning } = useToast()

  const visibleTableRef = useRef<HTMLDivElement>(null)

  const { data = [], isLoading } =
    trpc.simpleAssignment.getEmployees.useQuery(undefined)

  const filteredGroups = searchTerm
    ? searchEmployeeInGroups(data, searchTerm)
    : data

  const {
    areAllSelected,
    selectedSubRows: selectedEmployees,
    toggleSelectAll,
    isHeaderIndeterminate,
    isRowSelected,
    isRowIndeterminate,
    toggleRowSelection,
    toggleSubRowSelection,
  } = useRowAndSubRowSelection<Employee, Group>({
    data: filteredGroups,
    getSubRowId: (employees) => employees.id,
    getSubRows: (row) => row.employees,
    initialSelectedSubRows: selectedDefault.map((emp) => emp.id),
  })

  const table = useTableConfig({
    groups: filteredGroups,
    areAllSelected,
    isHeaderIndeterminate,
    isRowIndeterminate,
    isRowSelected,
    selectedEmployees,
    toggleRowSelection,
    toggleSelectAll,
  })

  const employeeMap = useMemo(() => {
    const map: { [key: string]: Employee } = {}

    data?.forEach((group) => {
      group.employees.forEach((employee) => {
        map[employee.id] = employee
      })
    })

    return map
  }, [data])

  const memoizedSelectedSubRows = useMemo(
    () => selectedEmployees,
    [selectedEmployees],
  )

  const memoizedTotal = useMemo(
    () => filteredGroups.length,
    [filteredGroups.length],
  )

  useDebounce(
    () => {
      const selectedEmployees = memoizedSelectedSubRows.map(
        (id) => employeeMap[id],
      )

      setSelectedEmployees(selectedEmployees)
    },
    DEBOUNCE_SELECTED_DELAY,
    [memoizedSelectedSubRows],
  )

  useDebounce(
    () => {
      if (searchTerm) {
        table.setPagination((prev) => ({ ...prev, pageNumber: 1 }))
      }
    },
    DEBOUNCE_SEARCH_PAGINATION_DELAY,
    [searchTerm],
  )

  const handleEditGroup = useCallback(() => {
    window.open('/settings/groups', '_blank')
  }, [])

  const handleContinue = useCallback(() => {
    if (!selectedEmployees.length) {
      toastWarning({
        title: t('newOrder.warnings.selectEmployee'),
      })

      return false
    }
    nextStep()
  }, [selectedEmployees.length, t, toastWarning, nextStep])

  const getVisibleTableRowHeight = useCallback(() => {
    if (visibleTableRef.current) {
      const tdElement = visibleTableRef.current.querySelector('tbody td')

      return tdElement instanceof HTMLElement
        ? tdElement.getBoundingClientRect().height
        : 0
    }

    return 0
  }, [])

  const getVisibleTableColumnWidths = useCallback(() => {
    if (visibleTableRef.current) {
      const tdElements = visibleTableRef.current.querySelectorAll(
        'tbody tr:first-child td',
      )

      const widths = Array.from(tdElements).map((td) =>
        td instanceof HTMLElement ? td.getBoundingClientRect().width : 0,
      )

      setTableColumnWidths(widths)
    }

    return []
  }, [])

  useEffect(() => {
    setContinueButtonOptions({
      text: t('newOrder.buttons.next'),
      onClick: handleContinue,
    })
  }, [setContinueButtonOptions, t, handleContinue])

  useEffect(() => {
    setSearchParams((params) => {
      params.delete('order')
      params.delete('schedule')
      return params
    })
  }, [setSearchParams])

  useEffect(() => {
    const tableElement = visibleTableRef.current

    if (tableElement) {
      getVisibleTableColumnWidths()

      const tdElements = tableElement.querySelectorAll(
        'tbody tr:first-child td',
      )
      const observer = new ResizeObserver(() =>
        requestAnimationFrame(getVisibleTableColumnWidths),
      )

      tdElements.forEach((td) => {
        if (td instanceof HTMLElement) {
          observer.observe(td)
        }
      })

      return () => {
        tdElements.forEach((td) => observer.unobserve(td))

        observer.disconnect()
      }
    }
  }, [getVisibleTableColumnWidths, isLoading])

  return (
    <EmployeeSelectionContext.Provider
      value={{
        tableHeaderGroups: table.getHeaderGroups,
        groups: table.rows,
        total: memoizedTotal,
        totalEmployee: getTotalEmployee(data),
        selectedEmployees,
        areAllSelected,
        isHeaderIndeterminate,
        toggleSelectAll,
        pagination: table.pagination,
        setPagination: table.setPagination,
        setSearchTerm,
        isLoading,
        handleEditGroup,
        toggleEmployeeSelection: toggleSubRowSelection,
        visibleTableRef,
        getVisibleTableRowHeight,
        tableColumnWidths,
      }}
    >
      {children}
    </EmployeeSelectionContext.Provider>
  )
}
