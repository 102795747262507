import styled from 'styled-components'

import { Box } from '../box'

export const BoxBorderedStyled = styled(Box.Bordered)`
  display: flex;
  padding: ${({ theme }) => `${theme.spacings.s} ${theme.spacings.m}`};
`

export const IconLabelContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.spacings.xs1};
`
