import { Emoji } from 'src/components/emoji'
import styled from 'styled-components'

import { OrderSummary as BenefitSummary } from './components/summary'

export const EmojiStyled = styled(Emoji)`
  background-color: transparent;
  padding: 0;
  border-radius: 0;
`

export const BenefitSummaryListStyled = styled(BenefitSummary.List)`
  gap: ${({ theme }) => theme.spacings.xs2};
`
