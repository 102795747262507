import { PillButton, Tooltip } from '@flash-tecnologia/hros-web-ui-v2'
import { useTranslation } from 'react-i18next'

type ActionCellProps = {
  isEmployee?: boolean
  expandable?: {
    isOpen: boolean
    handleToogle: (status: boolean) => void
  }
}

export function ActionCell({
  isEmployee = false,
  expandable,
}: ActionCellProps) {
  const { t } = useTranslation()

  const handleOpenSimpleAssignment = () => {
    window.open('/simple-assignment', '_blank')
  }

  if (isEmployee) {
    return (
      <Tooltip
        title={t('newOrder.employeeSelection.table.actionCell.tooltip')}
        placement="bottom-end"
      >
        <div>
          <PillButton
            icon="IconExternalLink"
            size="small"
            variant="default"
            onClick={handleOpenSimpleAssignment}
          />
        </div>
      </Tooltip>
    )
  }

  return (
    <PillButton
      icon={expandable?.isOpen ? 'IconChevronUp' : 'IconChevronDown'}
      size="small"
      variant="default"
      onClick={() => expandable?.handleToogle(!expandable.isOpen)}
    />
  )
}
