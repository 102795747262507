import Big from 'big.js'

// eslint-disable-next-line no-use-before-define
type MoneySource = Money | string | number

export class Money {
  readonly _value: Big

  constructor(moneySource: MoneySource) {
    this._value = this.initializeValue(moneySource)
  }

  get value() {
    return this._value
  }

  toString() {
    return this.value.toString()
  }

  toNumber() {
    return this.value.toNumber()
  }

  isNegative() {
    return this.value.toNumber() < 0
  }

  toCents() {
    return new Money(this.value.mul(100).round(0, 0).toNumber())
  }

  minus(money: MoneySource) {
    const result = this.value.minus(new Money(money).value)

    return new Money(result.toString())
  }

  plus(money: MoneySource) {
    const result = this.value.plus(new Money(money).value)

    return new Money(result.toString())
  }

  div(money: MoneySource) {
    const result = this.value.div(new Money(money).value)

    return new Money(result.toString())
  }

  mul(money: MoneySource) {
    const result = this.value.mul(new Money(money).value)

    return new Money(result.toString())
  }

  private initializeValue(moneySource: MoneySource) {
    if (moneySource instanceof Money) {
      return new Big(moneySource.value)
    }

    if (this.isValidSource(moneySource)) {
      return new Big(moneySource)
    }

    throw new Error('Invalid Money value')
  }

  private isValidSource(moneySource: MoneySource) {
    if (moneySource === null || moneySource === undefined) {
      return false
    }

    try {
      const value = new Big(
        moneySource instanceof Money ? moneySource.value : moneySource,
      )

      return !isNaN(value.toNumber())
    } catch (error) {
      return false
    }
  }
}
