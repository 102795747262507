import { BenefitTypeEnum } from 'src/enums/benefitTypeEnum'

import {
  DepositTimeUnitEnum,
  Employee,
} from '../../../steps/employee-selection/types'

export type GroupedBenefit = {
  emoji: string
  name: string
  amount: number
  depositTimeUnit: DepositTimeUnitEnum
  benefitType: BenefitTypeEnum
}

type BenefitMap = {
  [key: string]: GroupedBenefit
}

export function groupBenefits(
  employees: Employee[],
  creditDays: number,
): GroupedBenefit[] {
  const benefitMap: BenefitMap = {}

  employees.forEach((employee) => {
    employee.benefits.forEach((benefit) => {
      const key = benefit.id

      if (benefitMap[key]) {
        benefitMap[key].amount += benefit.value
      } else {
        benefitMap[key] = {
          emoji: benefit.url,
          name: benefit.name,
          amount: benefit.value,
          depositTimeUnit: benefit.depositTimeUnit,
          benefitType: benefit.benefitType,
        }
      }
    })
  })

  Object.values(benefitMap).forEach((benefit) => {
    if (benefit.depositTimeUnit === DepositTimeUnitEnum.DAY) {
      benefit.amount *= creditDays
    }
  })

  return Object.values(benefitMap).sort((a, b) => a.name.localeCompare(b.name))
}
