import { Button, Icons } from '@flash-tecnologia/hros-web-ui-v2'

import { useProcessingOrderStatus } from '../../hooks/use-processing-order-status'
import { StatusType } from '../processing-status'
import { StatusCard } from '../status-card'

export function StatusProcessing({
  totalLines,
  processedLines,
  percentageProgress,
}: StatusType) {
  const { droppedFile, navigateToOrders } = useProcessingOrderStatus()

  return (
    <StatusCard
      iconName={'IconRefresh'}
      iconColor="default"
      title="Em processamento"
      description="Assim que o processamento do seu arquivo for finalizado, notificaremos aqui na plataforma o resultado da importação :)"
      titleResult={droppedFile?.name || ''}
      descriptionResult={`${processedLines} de ${totalLines} pessoas importadas`}
      progress={percentageProgress}
      actionsButtons={[
        <Button
          key="stop-import"
          variant="secondary"
          size="large"
          onClick={navigateToOrders}
        >
          <Icons name="IconX" /> Interromper Importação
        </Button>,
      ]}
    />
  )
}
