import {
  HeaderGroup,
  Row,
} from '@flash-tecnologia/hros-web-ui-v2/dist/components/Table/shared/table.types'
import { createContext, ReactNode, useEffect, useState } from 'react'
import { useDebounce } from 'react-use'
import { BenefitTypeEnum } from 'src/enums/benefitTypeEnum'
import { Pagination } from 'src/utils/hooks/usePagination'
import { useRowSelection } from 'src/utils/hooks/useRowSelection'

import { useNewOrderStore } from '../../store/use-new-order-store'
import { DEBOUNCE_SEARCH_PAGINATION_DELAY } from './config/constants'
import { useEmployeeActions } from './hooks/use-employee-actions'
import { useEmployeeData } from './hooks/use-employee-data'
import { useEmployeeSelectionSync } from './hooks/use-employee-selection-sync'
import { useTableConfig } from './hooks/use-table-config'
import { Employee } from './types'

type EmployeeSelectionContextType = {
  benefitType: BenefitTypeEnum
  employees: Row<Employee>[]
  employeeTotal: number
  employeeSelectedTotal: number
  pagination: Pagination
  isLoading: boolean
  tableHeaderGroups: () => HeaderGroup<Employee>[]
  handleSearch: (value: string) => void
  setPagination: (pagination: Pagination) => void
  handleEditGroup: () => void
  handleContinue: () => void
  selectedAllEmployees: () => void
  clearSelectedRows: () => void
  toggleEmployeeSelection: (employeeId: string) => void
  setBenefitType: (benefitType: BenefitTypeEnum) => void
  areAllSelected: boolean
  noEmployeesData: boolean
  selectedEmployees: Employee[]
  activeFiltersCount: number
}

type EmployeeSelectionProps = {
  children: ReactNode
}

export const EmployeeSelectionContext =
  createContext<EmployeeSelectionContextType | null>(null)

export function EmployeeSelectionProvider({
  children,
}: EmployeeSelectionProps) {
  const [searchTerm, setSearchTerm] = useState('')

  const {
    benefitType,
    selectedEmployees,
    setContinueButtonOptions,
    setBenefitType,
  } = useNewOrderStore()

  // Hooks de dados
  const {
    employees,
    filteredEmployees,
    isLoading,
    activeFiltersCount,
    hasEmployees,
  } = useEmployeeData(searchTerm)

  // Hooks de seleção
  const {
    areAllSelected,
    isHeaderIndeterminate,
    selectedRows: selectedEmployeeIds,
    selectedData,
    isRowSelected,
    toggleSelectAll,
    toggleRowSelection,
    clearSelectedRows,
  } = useRowSelection({
    filteredRows: filteredEmployees,
    allData: employees,
    initialSelectedRows: selectedEmployees.map((employee) => employee.id),
  })

  // Hook de sincronização de seleção
  const { calculateSelectedTotal } = useEmployeeSelectionSync(
    selectedData,
    filteredEmployees,
    selectedEmployeeIds,
  )

  // Hook de ações
  const { handleEditGroup, handleContinue } = useEmployeeActions()

  // Hook de configuração da tabela
  const table = useTableConfig({
    employees: filteredEmployees,
    areAllSelected,
    isHeaderIndeterminate,
    selectedEmployeeIds,
    toggleSelectAll,
    isRowSelected,
    toggleRowSelection,
  })

  // Atualiza paginação quando houver busca
  useDebounce(
    () => {
      if (searchTerm) {
        table.setPagination((prev) => ({ ...prev, pageNumber: 1 }))
      }
    },
    DEBOUNCE_SEARCH_PAGINATION_DELAY,
    [searchTerm],
  )

  useEffect(() => {
    setContinueButtonOptions({
      text: 'Continuar',
      onClick: handleContinue,
    })
  }, [handleContinue, setContinueButtonOptions])

  const employeeSelectedTotal = calculateSelectedTotal(
    activeFiltersCount,
    searchTerm,
  )

  const contextValue: EmployeeSelectionContextType = {
    benefitType,
    setBenefitType,
    employees: table.rows,
    selectedEmployees,
    employeeTotal: table.total,
    employeeSelectedTotal,
    noEmployeesData: !hasEmployees,
    isLoading,
    tableHeaderGroups: table.getHeaderGroups,
    pagination: table.pagination,
    setPagination: table.setPagination,
    toggleEmployeeSelection: toggleRowSelection,
    selectedAllEmployees: toggleSelectAll,
    clearSelectedRows,
    areAllSelected,
    handleSearch: setSearchTerm,
    activeFiltersCount,
    handleEditGroup,
    handleContinue,
  }

  return (
    <EmployeeSelectionContext.Provider value={contextValue}>
      {children}
    </EmployeeSelectionContext.Provider>
  )
}
