import { dayjs } from '@flash-tecnologia/hros-web-ui-v2'
import Grid from '@mui/material/Grid'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Checkout from 'src/components/checkout'
import { useOrder } from 'src/domain/checkout/context/order-context'
import { PaymentMethodEnum } from 'src/enums/paymentMethodEnum'
import { useTheme } from 'styled-components'

import { IBilletDueDateProps } from './billet/billet-due-date'
import { CutoffDate } from './cutoff-date'
import { CutoffTime } from './cutoff-time'
import { DepositDate } from './deposit-date'
import { IPixDueDateProps } from './pix/pix-due-date'

interface IDepositDateProps {
  disabled?: boolean
  value?: dayjs.Dayjs
  cutoffValue?: dayjs.Dayjs
  onDateChange: (_value: dayjs.Dayjs | undefined) => void
  onCutoffDateChange?: (_value: dayjs.Dayjs | undefined) => void
  dueDateInput?: React.ReactElement<IBilletDueDateProps | IPixDueDateProps>
  paymentMethod: PaymentMethodEnum
  isTopup?: boolean
}

export const CheckoutDatesInputs: React.FC<IDepositDateProps> = ({
  disabled,
  onDateChange,
  onCutoffDateChange,
  value,
  cutoffValue,
  dueDateInput: DueDateInput,
  paymentMethod,
  isTopup = false,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { creditConfigs } = useOrder()

  const initialCutoffDate = cutoffValue || dayjs().add(1, 'day')
  const initialCutoffTime = cutoffValue || dayjs().startOf('day')
  const toDate = dayjs().add(6, 'months').subtract(2, 'day')

  const [cutoffDate, setCutoffDate] = useState(initialCutoffDate)
  const [cutoffTime, setCutoffTime] = useState(initialCutoffTime)

  const handleCutoffDateChange = React.useCallback((date?: dayjs.Dayjs) => {
    if (dayjs(date).isValid()) {
      setCutoffDate((prevDate) =>
        date && !date.isSame(prevDate) ? dayjs(date) : prevDate,
      )
    }
  }, [])

  const handleCutoffTimeChange = React.useCallback(
    (date: dayjs.Dayjs | null) => {
      if (dayjs(date).isValid()) {
        setCutoffTime((prevTime) =>
          date && !date.isSame(prevTime) ? dayjs(date) : prevTime,
        )
      }
    },
    [],
  )

  const checkIsWeekend = (date: dayjs.Dayjs) => {
    const SUNDAY_INDEX = 0
    const SATURDAY_INDEX = 6

    return [SUNDAY_INDEX, SATURDAY_INDEX].includes(dayjs(date).day())
  }

  const isDateDisabled = React.useCallback(
    (dateToCheck: dayjs.Dayjs) => {
      let minDate: dayjs.Dayjs = dayjs().startOf('day')
      const MAX_DEPOSIT_DATE = minDate.add(6, 'months')

      if (paymentMethod === PaymentMethodEnum.BILLET) {
        // @ts-expect-error: Type error
        minDate = dayjs().tz()
        const currentDay = minDate.hour() < 22 ? 1 : 2

        // @ts-expect-error: Type error
        minDate = minDate.startOf('day').addBusinessDays(currentDay)
      }

      const isBeforeMinDate: boolean = dateToCheck.isBefore(minDate)
      const isAfterMaxDate: boolean = dateToCheck.isAfter(MAX_DEPOSIT_DATE)
      const isWeekend: boolean =
        checkIsWeekend(dateToCheck) && !creditConfigs?.weekendDaysEnabled
      const isHoliday: boolean =
        // @ts-expect-error:  Type error

        dateToCheck.isHoliday() && !creditConfigs?.holidayDaysEnabled

      return isBeforeMinDate || isAfterMaxDate || isWeekend || isHoliday
    },
    [paymentMethod, creditConfigs],
  )

  const disabledDepositDate = React.useCallback(
    (date: Date) => {
      const dateToCheck = dayjs(date)
      let isBeforeCutoffDate = false

      if (isTopup) {
        const combinedCutoffDateTime = cutoffDate
          .set('hour', cutoffTime.hour())
          .set('minute', cutoffTime.minute())

        isBeforeCutoffDate =
          dateToCheck.isBefore(combinedCutoffDateTime) ||
          dateToCheck.isSame(combinedCutoffDateTime)
      }

      return isDateDisabled(dateToCheck) || isBeforeCutoffDate
    },
    [isDateDisabled, isTopup, cutoffDate, cutoffTime],
  )

  const disabledCutoffDate = React.useCallback((date: Date) => {
    const currentDate = dayjs(date)
    const tomorrow = dayjs().add(1, 'day').startOf('day')

    return currentDate.isBefore(tomorrow)
  }, [])

  useEffect(() => {
    const combinedCutoffDateTime = cutoffDate
      .set('hour', cutoffTime.hour())
      .set('minute', cutoffTime.minute())

    if (onCutoffDateChange && combinedCutoffDateTime) {
      onCutoffDateChange(combinedCutoffDateTime)
    }
  }, [cutoffDate, cutoffTime])

  useEffect(() => {
    if (isTopup) {
      const combinedCutoffDateTime = cutoffDate
        .set('hour', cutoffTime.hour())
        .set('minute', cutoffTime.minute())
      let nextAvailableDate = combinedCutoffDateTime.add(1, 'day')

      while (isDateDisabled(nextAvailableDate)) {
        nextAvailableDate = nextAvailableDate.add(1, 'day')
      }

      if (
        value?.isBefore(combinedCutoffDateTime) ||
        value?.isSame(combinedCutoffDateTime, 'day') ||
        !value
      ) {
        onDateChange(nextAvailableDate)
      }
    }
  }, [isDateDisabled, cutoffDate, cutoffTime, isTopup, onDateChange, value])

  return (
    <Grid container direction="column" rowGap={{ xs: theme.spacings.s }}>
      <Grid container direction="column" rowSpacing={3}>
        <Grid container item direction="column" rowGap={theme.spacings.xs5}>
          <Checkout.Content.Text.Title>
            {t('order.checkoutDates.availabilityDate.title')}
          </Checkout.Content.Text.Title>
          <Checkout.Content.Text.Subtitle>
            {t('order.checkoutDates.availabilityDate.subtitle')}
          </Checkout.Content.Text.Subtitle>
        </Grid>
        <Grid
          container
          item
          direction="row"
          justifyContent={'space-between'}
          alignItems={'flex-end'}
          rowGap={{ xs: theme.spacings.xs }}
        >
          <DepositDate
            value={value}
            disabled={disabled}
            onDateChange={onDateChange}
            disabledDate={disabledDepositDate}
          />
          {DueDateInput ?? null}
        </Grid>
      </Grid>

      {isTopup && (
        <Grid container direction="column" rowSpacing={3}>
          <Grid container item direction="column" rowGap={theme.spacings.xs5}>
            <Checkout.Content.Text.Title>
              {t('order.checkoutDates.cutoffDate.title')}
            </Checkout.Content.Text.Title>
            <Checkout.Content.Text.Subtitle>
              {t('order.checkoutDates.cutoffDate.subtitle')}
            </Checkout.Content.Text.Subtitle>
          </Grid>
          <Grid
            container
            item
            direction="row"
            justifyContent={'space-between'}
            alignItems={'flex-end'}
            rowGap={theme.spacings.xs}
          >
            <CutoffDate
              value={cutoffDate}
              onDateChange={handleCutoffDateChange}
              disabledDate={disabledCutoffDate}
              toDate={toDate}
            />

            <CutoffTime
              value={cutoffTime as never}
              onChange={handleCutoffTimeChange}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
