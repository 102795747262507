import { useCallback, useContext, useMemo, useState } from 'react'
import { BenefitTypeEnum } from 'src/enums/benefitTypeEnum'
import { redirectToURL } from 'src/utils/redirectToURL'

import { EmployeeSelectionContext } from '../context'

export function useEmployeeSelection() {
  const context = useContext(EmployeeSelectionContext)

  if (!context) {
    throw new Error(
      'useEmployeeSelectionContext must be used within an EmployeeSelectionProvider',
    )
  }

  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false)
  const [isBulkUpdateModalOpen, setIsBulkUpdateModalOpen] = useState(false)

  const isBenefitTypePlastic = useMemo(() => {
    return context.benefitType === BenefitTypeEnum.PLASTIC
  }, [context.benefitType])

  const isBenefitTypeVirtual = useMemo(() => {
    return context.benefitType === BenefitTypeEnum.VIRTUAL
  }, [context.benefitType])

  const openFilterDrawer = useCallback(() => {
    setIsFilterDrawerOpen(true)
  }, [])

  const closeFilterDrawer = useCallback(() => {
    setIsFilterDrawerOpen(false)
  }, [])

  const openBulkUpdateModal = useCallback(() => {
    setIsBulkUpdateModalOpen(true)
  }, [])

  const closeBulkUpdateModal = useCallback(() => {
    setIsBulkUpdateModalOpen(false)
  }, [])

  const redirectToEmployees = useCallback(() => {
    redirectToURL('/employees')
  }, [])

  const setBenefitTypePlastic = useCallback(() => {
    context.setBenefitType(BenefitTypeEnum.PLASTIC)
  }, [context])

  const setBenefitTypeVirtual = useCallback(() => {
    context.setBenefitType(BenefitTypeEnum.VIRTUAL)
  }, [context])

  return {
    ...context,
    isFilterDrawerOpen,
    isBulkUpdateModalOpen,
    openFilterDrawer,
    closeFilterDrawer,
    openBulkUpdateModal,
    closeBulkUpdateModal,
    redirectToEmployees,
    isBenefitTypePlastic,
    isBenefitTypeVirtual,
    setBenefitTypePlastic,
    setBenefitTypeVirtual,
  }
}
