import { Button, ButtonProps, Icons } from '@flash-tecnologia/hros-web-ui-v2'

import { StepNavigationButtonOptions } from '../../store/use-new-order-store'

export function StepNavigationButton(
  options: StepNavigationButtonOptions & ButtonProps,
) {
  const {
    text,
    iconName = 'IconArrowRight',
    iconPosition = 'right',
    isLoading = false,
    onClick,
    showIcon = true,
    variant,
    size = 'medium',
    disabled = false,
  } = options

  return (
    <Button
      variant={variant}
      size={size}
      onClick={onClick}
      loading={isLoading}
      disabled={disabled}
    >
      {showIcon && iconPosition === 'left' && (
        <Icons name={iconName} fill="transparent" />
      )}

      {text}

      {showIcon && iconPosition === 'right' && (
        <Icons name={iconName} fill="transparent" />
      )}
    </Button>
  )
}
