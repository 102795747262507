import styled from 'styled-components'

export const OrderSummaryContainerStyled = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: ${({ theme }) => theme.borders.radius.m};
  border-width: ${({ theme }) => theme.borders.width.xs2};
  border-color: ${({ theme }) => theme.colors.neutral90};
  border-style: solid;

  height: fit-content;
`
