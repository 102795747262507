import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { Grid } from 'src/components/grid'
import { useTheme } from 'styled-components'

import { GridContainerStyled, TitleStyled } from '../styles'
import { MigrateEmployees } from './components/migrate-employees'
import { ReactivateEmployees } from './components/reactivate-employees'
import { ScheduleNotification } from './components/schedule-notification'

export function AddEmployeesForm() {
  const theme = useTheme()

  return (
    <>
      <TitleStyled variant="headline6" weight={700} color="neutral.20">
        Pedido de benefício por planilha com modelo Flash
      </TitleStyled>
      <GridContainerStyled
        display="grid"
        flexDirection="row"
        gap={theme.spacings.xs}
      >
        <Grid.Item flexDirection="column">
          <TitleStyled variant="headline7" weight={700} color="neutral.20">
            Configurações
          </TitleStyled>
          <Grid.Container gap={theme.spacings.xs}>
            <Typography variant="body3" weight={400} color="neutral.40">
              Gerencie casos de cadastros duplicados e escolha como enviar os
              convites de primeiro acesso para as pessoas importadas, permitindo
              que elas criem suas contas e acessem a plataforma.
            </Typography>
            <Typography variant="body3" weight={400} color="neutral.40">
              Os CPFs já cadastrados terão seus dados atualizados com os dados
              da planilha.
            </Typography>
          </Grid.Container>
        </Grid.Item>
        <Grid.Container flexDirection="column" gap={theme.spacings.m}>
          <ScheduleNotification />
          <MigrateEmployees />
          <ReactivateEmployees />
        </Grid.Container>
      </GridContainerStyled>
    </>
  )
}
