import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from 'src/components/grid'
import { useTheme } from 'styled-components'

import { CreditTypeRadioCard } from '../credit-type-radio-card'
import { ModalContentStyled } from './styles'

export enum CreditTypeEnum {
  ACCUMULATIVE = 'accumulative',
  TOPUP = 'topup',
}

type CreditTypeProps = {
  selectedValue?: string
  onSelect: (creditType: CreditTypeEnum) => void
}

export function CreditType({ selectedValue, onSelect }: CreditTypeProps) {
  const theme = useTheme()
  const { t } = useTranslation()

  const options = useMemo(
    () => [
      {
        value: CreditTypeEnum.ACCUMULATIVE,
        titleKey:
          'orders.content.modalPreOrder.creditType.content.options.0.title',
        descriptionKey:
          'orders.content.modalPreOrder.creditType.content.options.0.description',
      },
      {
        value: CreditTypeEnum.TOPUP,
        titleKey:
          'orders.content.modalPreOrder.creditType.content.options.1.title',
        descriptionKey:
          'orders.content.modalPreOrder.creditType.content.options.1.description',
      },
    ],
    [],
  )

  return (
    <ModalContentStyled>
      <Typography variant="body3" color="neutral.20" weight={700}>
        {t('orders.content.modalPreOrder.creditType.content.title')}
      </Typography>

      <Grid.Container
        flexDirection="row"
        gap={theme.spacings.xs}
        marginTop={theme.spacings.xs}
        wrap="nowrap"
      >
        {options.map((option) => (
          <CreditTypeRadioCard
            key={option.value}
            title={t(option.titleKey)}
            description={t(option.descriptionKey)}
            selected={selectedValue === option.value}
            onSelect={() => onSelect(option.value)}
          />
        ))}
      </Grid.Container>
    </ModalContentStyled>
  )
}
