import {
  PillButton,
  ShapeIcon,
  ShapeIconProps,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2'

import { BoxBorderedStyled, IconLabelContainerStyled } from './styles'

type DownloadButtonType = {
  onClick?: () => void
  iconLeft: ShapeIconProps['name']
  iconLeftColor: ShapeIconProps['variant']
  iconRight: ShapeIconProps['name']
  iconRightColor: 'pink' | 'error' | 'info' | 'success' | 'default'
  label: string
  width?: string
  iconLeftSize?: number
  iconRightSize?: 'extra-small' | 'small' | 'medium' | 'large'
}

export function DownloadButton({
  onClick,
  iconLeft,
  iconLeftColor,
  iconRight,
  iconRightColor,
  label,
  width,
  iconLeftSize = 48,
  iconRightSize = 'medium',
}: DownloadButtonType) {
  return (
    <BoxBorderedStyled
      width={width}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <IconLabelContainerStyled>
        <ShapeIcon
          size={iconLeftSize}
          variant={iconLeftColor}
          stroke={iconLeftColor}
          strokeWidth="2"
          name={iconLeft}
        />
        <Typography variant="body3" weight={700} color="neutral.20">
          {label}
        </Typography>
      </IconLabelContainerStyled>
      <PillButton
        onClick={onClick}
        size={iconRightSize}
        variant={iconRightColor}
        icon={iconRight}
      />
    </BoxBorderedStyled>
  )
}
