import { useCallback, useEffect, useRef, useState } from 'react'
import { trpc } from 'src/api/client'
import { useToast } from 'src/utils/hooks/useToast'

import { useOrderBySpreadsheetStore } from '../../../store/use-order-by-spreadsheet-store'

type DropzoneFile = {
  id: string
  data?: string | ArrayBuffer | null | undefined
  progress: number
  loaded: boolean
  error: boolean
  file: File
  mime?: string
}

enum SpreadsheetUploadStatus {
  IDLE,
  UPLOADING,
  STARTING_LOAD,
  FINISHED,
}

export function useFlashSpreadsheet() {
  const { toastWarning, toastError } = useToast()

  const {
    setFileId,
    uploadUrl,
    setUploadUrl,
    droppedFile,
    setDroppedFile,
    setContinueButtonOptions,
    nextStep,
    fileId,
  } = useOrderBySpreadsheetStore()

  const [isOnlyOrder, setIsOnlyOrder] = useState(true)
  const [spreadsheetUploadStatus, setSpreadsheetUploadStatus] =
    useState<SpreadsheetUploadStatus>(SpreadsheetUploadStatus.IDLE)

  const hasFetchedUploadUrl = useRef(false)

  const {
    data: spreadsheetPath,
    isLoading: isLoadingSpreadsheetPath,
    isError: isErrorSpreadsheetPath,
  } = trpc.benefitOrders.getCompanySpreadsheet.useQuery()

  const loadSpreadsheetMutation = useRef(
    trpc.benefitOrders.loadSpreadsheet.useMutation({
      onMutate: () => {
        setSpreadsheetUploadStatus(SpreadsheetUploadStatus.STARTING_LOAD)
      },
      onError: () => {
        toastError({
          title: 'Não foi possível processar arquivo enviado',
          description: 'Por favor, remova o arquivo e envie novamente.',
        })
        setSpreadsheetUploadStatus(SpreadsheetUploadStatus.IDLE)
      },
    }),
  ).current

  const { mutate: fetchUploadUrl, isLoading: isFetchingUploadUrl } =
    trpc.benefitOrders.createSpreadsheetUploadUrl.useMutation({
      onSuccess: (data) => {
        setFileId(data.fileId)
        setUploadUrl(data.signedUrl)
      },
      onError: () => {
        toastError({
          title: 'Não foi possível processar arquivo enviado',
          description: 'Por favor, remova o arquivo e envie novamente.',
        })
      },
    })

  const handleFileChange = useCallback(
    (files: DropzoneFile[]) => {
      const file = files?.[0]?.file ?? null

      setDroppedFile(file)

      if (file && (!uploadUrl || !fileId) && !hasFetchedUploadUrl.current) {
        fetchUploadUrl()
        hasFetchedUploadUrl.current = true
      }
    },
    [fetchUploadUrl, fileId, setDroppedFile, uploadUrl],
  )

  const handleFileRemove = useCallback(() => {
    hasFetchedUploadUrl.current = false
  }, [])

  const uploadFile = useCallback(
    async (signedUrl: string, file: File) => {
      setSpreadsheetUploadStatus(SpreadsheetUploadStatus.UPLOADING)

      try {
        await fetch(signedUrl, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type,
          },
        })
      } catch (error) {
        toastError({
          title: 'Não foi possível realizar o envio do arquivo',
          description:
            'Tente novamente mais tarde, ou entre em contato com a nossa Central de Ajuda.',
        })
        setSpreadsheetUploadStatus(SpreadsheetUploadStatus.IDLE)
        throw error
      }
    },
    [toastError],
  )

  const handleUploadSpreadsheet = useCallback(async () => {
    if (!droppedFile || !uploadUrl) {
      toastWarning({
        title: 'Faça o envio do arquivo para continuar',
        description:
          'É necessário enviar o arquivo preenchido com as informações do pedido de benefício para continuar',
      })

      return
    }

    await uploadFile(uploadUrl, droppedFile)

    if (isOnlyOrder && fileId) {
      await loadSpreadsheetMutation.mutateAsync({
        fileId,
      })
    }

    setSpreadsheetUploadStatus(SpreadsheetUploadStatus.FINISHED)

    nextStep(isOnlyOrder)
  }, [
    fileId,
    uploadUrl,
    isOnlyOrder,
    loadSpreadsheetMutation,
    droppedFile,
    toastWarning,
    uploadFile,
    nextStep,
  ])

  useEffect(() => {
    setContinueButtonOptions({
      text: 'Continuar',
      onClick: handleUploadSpreadsheet,
      isLoading:
        isFetchingUploadUrl ||
        spreadsheetUploadStatus !== SpreadsheetUploadStatus.IDLE,
    })
  }, [
    handleUploadSpreadsheet,
    isFetchingUploadUrl,
    setContinueButtonOptions,
    spreadsheetUploadStatus,
  ])

  useEffect(() => {
    if (isErrorSpreadsheetPath) {
      toastError({
        title: 'Não foi possível obter o modelo de planilha',
        description:
          'Caso precise baixar o modelo de planilha, por favor, recarregue a página e tente novamente.',
      })
    }
  }, [isErrorSpreadsheetPath, toastError])

  const handleOnlyOrder = useCallback(() => {
    setIsOnlyOrder((prev) => !prev)
  }, [])

  return {
    handleFileChange,
    handleFileRemove,
    handleOnlyOrder,
    isLoadingSpreadsheetPath,
    isOnlyOrder,
    spreadsheetPath: spreadsheetPath?.path,
  }
}
