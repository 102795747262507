import { colors, ThemesType } from '@flash-tecnologia/hros-web-ui-v2'
import { CSSProperties, ReactNode } from 'react'
import styled from 'styled-components'

// Tokens de espaçamento e raio
type SpacingToken = keyof ThemesType['spacings'] | 'none'
type RadiusToken = keyof ThemesType['borders']['radius']
type BorderWidth = keyof ThemesType['borders']['width']

// Tipo genérico para aceitar de 1 a 4 tokens
type BaseProp<T extends string> =
  | T
  | `${T} ${T}`
  | `${T} ${T} ${T}`
  | `${T} ${T} ${T} ${T}`

type Radius = BaseProp<RadiusToken>
type Spacing = BaseProp<SpacingToken>

type Padding = Spacing
type Margin = Spacing
type Gap = Spacing

// Cores e alinhamentos
type Color = Parameters<typeof colors.get>[number]
type Align =
  | 'center'
  | 'start'
  | 'end'
  | 'flex-start'
  | 'flex-end'
  | 'space-between'

export type BoxProps = {
  width?: CSSProperties['width']
  display?: 'grid' | 'flex'
  flexDirection?: 'row' | 'column'
  gridTemplateColumns?: string
  gap?: Gap
  alignItems?: Align
  justifyContent?: Align
  padding?: Padding
  margin?: Margin
  radius?: Radius
  borderWidth?: BorderWidth
  borderColor?: Color
  backgroundColor?: Color
  children: ReactNode
}

function formatSpacing(theme: ThemesType, spacing?: Spacing) {
  return spacing
    ?.split(' ')
    .map((token) =>
      token === 'none'
        ? 0
        : theme.spacings[token as Exclude<SpacingToken, 'none'>],
    )
    .join(' ')
}

function formatRadius(theme: ThemesType, radius?: Radius) {
  return radius
    ?.split(' ')
    .map((token) => theme.borders.radius[token as RadiusToken] ?? 0)
    .join(' ')
}

export const BaseBoxStyled = styled.div<BoxProps>((props) => ({
  ...(props.width && { width: props.width }),
  ...(props.display && { display: props.display }),
  ...(props.flexDirection && { flexDirection: props.flexDirection }),
  ...(props.gridTemplateColumns && {
    gridTemplateColumns: props.gridTemplateColumns,
  }),
  ...(props.gap && { gap: formatSpacing(props.theme, props.gap) }),
  ...(props.alignItems && { alignItems: props.alignItems }),
  ...(props.justifyContent && { justifyContent: props.justifyContent }),
  ...(props.padding && {
    padding: formatSpacing(props.theme, props.padding),
  }),
  ...(props.margin && {
    margin: formatSpacing(props.theme, props.margin),
  }),
  ...(props.radius && {
    borderRadius: formatRadius(props.theme, props.radius),
    overflow: 'hidden',
  }),
  ...(props.borderWidth && {
    borderWidth: props.theme.borders.width[props.borderWidth],
  }),
  ...(props.borderColor && {
    borderColor: colors.get(props.borderColor),
  }),
  ...(props.backgroundColor && {
    backgroundColor: colors.get(props.backgroundColor),
  }),
}))
