import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { trpc } from 'src/api/client'
import { openNewTab } from 'src/utils/openNewTab'

import { useOrderBySpreadsheetStore } from '../../../store/use-order-by-spreadsheet-store'

const POOLING_INTERVAL = 1000

export enum SpreadsheetProcessingStatus {
  PROCESSING = 'processing',
  PROCESSED = 'processed',
  PARTIALLY_PROCESSED = 'partially_processed',
}

export function useProcessingOrderStatus() {
  const navigate = useNavigate()

  const {
    fileId,
    setContinueButtonOptions,
    setBackButtonOptions,
    droppedFile,
    resetStore,
  } = useOrderBySpreadsheetStore()

  const [isPolling, setIsPolling] = useState(true)

  const { data: spreadsheetProgress } =
    trpc.benefitOrders.getSpreadsheetProgress.useQuery(
      {
        fileId: fileId ?? '',
      },
      {
        refetchInterval: isPolling ? POOLING_INTERVAL : false,
        onSuccess: (data) => {
          if (
            data.processed === data.total &&
            data.processed !== 0 &&
            data.total !== 0
          ) {
            setIsPolling(false)
          }
        },
      },
    )

  const downloadErrorsFile = useCallback(() => {
    if (spreadsheetProgress?.errorsFileUrl) {
      openNewTab(spreadsheetProgress?.errorsFileUrl)
    }
  }, [spreadsheetProgress?.errorsFileUrl])

  const navigateToOrders = useCallback(() => {
    resetStore()
    navigate('/orders')
  }, [navigate, resetStore])

  const navigateToPaymentSetup = useCallback(() => {
    // @ToDo: será feito em tasks posteriores
    console.log('GO TO PAYMENT SETUP')
  }, [])

  const makeNewSpreadsheetOrder = useCallback(() => {
    resetStore()
  }, [resetStore])

  const status = useMemo(() => {
    if (spreadsheetProgress) {
      const { total, processed, hasErrors, errorsFileUrl } = spreadsheetProgress

      const processingFinish = total > 0 && processed === total

      if (processingFinish) {
        if (hasErrors && errorsFileUrl) {
          return SpreadsheetProcessingStatus.PARTIALLY_PROCESSED
        }

        if (!hasErrors) {
          return SpreadsheetProcessingStatus.PROCESSED
        }
      }
    }

    return SpreadsheetProcessingStatus.PROCESSING
  }, [spreadsheetProgress])

  const percentageProgress = useMemo(() => {
    if (spreadsheetProgress && spreadsheetProgress.total) {
      const { total, processed } = spreadsheetProgress

      return Math.round((processed * 100) / total)
    }
    return 0
  }, [spreadsheetProgress])

  useEffect(() => {
    setContinueButtonOptions(null)
    setBackButtonOptions(null)
  }, [setContinueButtonOptions, setBackButtonOptions])

  return {
    droppedFile,
    errorsFileUrl: spreadsheetProgress?.errorsFileUrl ?? '',
    fileId,
    percentageProgress,
    processedLines: spreadsheetProgress?.processed ?? 0,
    status,
    totalLines: spreadsheetProgress?.total ?? 0,
    downloadErrorsFile,
    makeNewSpreadsheetOrder,
    navigateToOrders,
    navigateToPaymentSetup,
  }
}
