import { dayjs, IconTypes } from '@flash-tecnologia/hros-web-ui-v2'
import { GetOrderSummaryResponse } from 'backend/src/services'
import { BenefitTypeEnum } from 'src/enums/benefitTypeEnum'
import { PaymentMethodEnum } from 'src/enums/paymentMethodEnum'
import { processEnv } from 'src/utils/env'
import { create } from 'zustand'

import { FilterKey } from '../steps/employee-selection/components/filters/hooks/use-filters'
import { Benefit, Employee } from '../steps/employee-selection/types'
import { updateBenefits } from './helpers/update-benefits'

export const CREDIT_DAYS_DEFAULT = 30

export enum Step {
  EMPLOYEE_SELECTION = 0,
  PAYMENT_SETUP = 1,
  REVIEW_DETAILS = 2,
  ORDER_COMPLETED = 3,
}

type Order = {
  id?: string
  paymentMethod?: PaymentMethodEnum
  depositDate?: dayjs.Dayjs
  dueDate?: dayjs.Dayjs
  cutoffDate?: dayjs.Dayjs
  receiptDescription?: string
  pixCode?: string
}

export type FlashCashBalance = {
  label: 'plastic' | 'virtual'
  value: number
}

export type StepNavigationButtonOptions = {
  text: string
  onClick?: () => void
  isLoading?: boolean
  showIcon?: boolean
  iconName?: IconTypes
  iconPosition?: 'left' | 'right'
  disabled?: boolean
}

type UpdateEmployeeBenefitValue = {
  employeeId: string
  updatedBenefits: Benefit[]
}

// Utility Functions
const filterBenefitsByType = (
  benefits: Benefit[],
  benefitType: BenefitTypeEnum,
): Benefit[] =>
  benefits.filter(
    (benefit) => benefit.benefitType === benefitType && benefit.value > 0,
  )

const updateEmployeeBenefits = (
  employee: Employee,
  updatedBenefits: Benefit[],
): Employee => ({
  ...employee,
  benefits: updateBenefits(employee.benefits, updatedBenefits),
})

const filterEmployeesByBenefitType = (
  employees: Employee[],
  benefitType: BenefitTypeEnum,
): Employee[] =>
  employees.map((employee) => ({
    ...employee,
    benefits: filterBenefitsByType(employee.benefits, benefitType),
  }))

// Navigation Constants
const STEP_NAVIGATION = {
  next: {
    [Step.EMPLOYEE_SELECTION]: Step.PAYMENT_SETUP,
    [Step.PAYMENT_SETUP]: Step.REVIEW_DETAILS,
    [Step.REVIEW_DETAILS]: Step.ORDER_COMPLETED,
    [Step.ORDER_COMPLETED]: Step.ORDER_COMPLETED,
  },
  previous: {
    [Step.EMPLOYEE_SELECTION]: Step.EMPLOYEE_SELECTION,
    [Step.PAYMENT_SETUP]: Step.EMPLOYEE_SELECTION,
    [Step.REVIEW_DETAILS]: Step.PAYMENT_SETUP,
    [Step.ORDER_COMPLETED]: Step.ORDER_COMPLETED,
  },
} as const

// Store Types
type NewOrderStore = {
  // State
  employees: Employee[]
  selectedEmployees: Employee[]
  employeesFilter: Record<FilterKey, string[]>
  isEmployeesInitialized: boolean
  order: Partial<Order> | null
  orderSummary: GetOrderSummaryResponse | null
  benefitType: BenefitTypeEnum
  currentStep: Step
  continueButtonOptions: StepNavigationButtonOptions | null
  backButtonOptions: StepNavigationButtonOptions | null
  flashCashBalances: FlashCashBalance[]
  isTopupCredit: boolean
  creditDays: number

  // Employee Actions
  setEmployees: (employees: Employee[]) => void
  setSelectedEmployees: (employees: Employee[]) => void
  setFilterEmployees: (employeesFilter: Record<FilterKey, string[]>) => void
  markEmployeesAsInitialized: () => void
  updateEmployeeBenefitValue: (update: UpdateEmployeeBenefitValue) => void
  updateSelectedEmployeesBenefitValue: (updatedBenefits: Benefit[]) => void
  setBenefitsForSelectedEmployees: (
    employees: Employee[],
    updatedBenefits: Benefit[],
  ) => void

  // Order Actions
  setOrder: (order: Partial<Order>) => void
  setOrderSummary: (summary: GetOrderSummaryResponse | null) => void
  setBenefitType: (benefitType: BenefitTypeEnum) => void
  resetOrder: () => void

  // Navigation Actions
  nextStep: () => void
  previousStep: () => void
  setContinueButtonOptions: (options: StepNavigationButtonOptions) => void
  setBackButtonOptions: (options: StepNavigationButtonOptions) => void

  // Payment Actions
  setFlashCashBalances: (balances: FlashCashBalance[]) => void
  setIsTopupCredit: (isTopup: boolean) => void
  setCreditDays: (days: number) => void

  // Reset Action
  resetStore: () => void
}

const initialState = {
  employees: [],
  selectedEmployees: [],
  employeesFilter: { groups: [] },
  isEmployeesInitialized: false,
  order: null,
  orderSummary: null,
  benefitType: BenefitTypeEnum.PLASTIC,
  currentStep: Step.EMPLOYEE_SELECTION,
  continueButtonOptions: null,
  backButtonOptions: null,
  flashCashBalances: [],
  isTopupCredit: false,
  creditDays: CREDIT_DAYS_DEFAULT,
}

export const useNewOrderStore = create<NewOrderStore>((set, get) => ({
  ...initialState,

  // Employee Actions
  setEmployees: (employees) => set({ employees }),

  setSelectedEmployees: (employees) => {
    const { benefitType } = get()
    set({
      selectedEmployees: filterEmployeesByBenefitType(employees, benefitType),
    })
  },

  setFilterEmployees: (employeesFilter) => set({ employeesFilter }),

  markEmployeesAsInitialized: () => set({ isEmployeesInitialized: true }),

  updateEmployeeBenefitValue: ({ employeeId, updatedBenefits }) => {
    set((state) => {
      const updateIfMatch = (employee: Employee) =>
        employee.id === employeeId
          ? updateEmployeeBenefits(employee, updatedBenefits)
          : employee

      return {
        employees: state.employees.map(updateIfMatch),
        selectedEmployees: state.selectedEmployees.map(updateIfMatch),
      }
    })
  },

  updateSelectedEmployeesBenefitValue: (updatedBenefits) => {
    set((state) => ({
      employees: state.employees.map((employee) =>
        updateEmployeeBenefits(employee, updatedBenefits),
      ),
      selectedEmployees: state.selectedEmployees.map((employee) =>
        updateEmployeeBenefits(employee, updatedBenefits),
      ),
    }))
  },

  setBenefitsForSelectedEmployees: (employees, updatedBenefits) => {
    set((state) => {
      const isSelected = (employee: Employee) =>
        employees.some((selected) => selected.id === employee.id)

      const updateIfSelected = (employee: Employee) =>
        isSelected(employee)
          ? updateEmployeeBenefits(employee, updatedBenefits)
          : employee

      return {
        employees: state.employees.map(updateIfSelected),
        selectedEmployees: state.selectedEmployees.map(updateIfSelected),
      }
    })
  },

  // Order Actions
  setOrder: (order) =>
    set((state) => ({
      order: { ...state.order, ...order },
    })),

  setOrderSummary: (summary) => set({ orderSummary: summary }),

  setBenefitType: (benefitType) => set({ benefitType }),

  resetOrder: () => set({ order: initialState.order }),

  // Navigation Actions
  nextStep: () =>
    set((state) => ({
      currentStep: STEP_NAVIGATION.next[state.currentStep],
      continueButtonOptions: null,
      backButtonOptions: null,
    })),

  previousStep: () =>
    set((state) => ({
      currentStep: STEP_NAVIGATION.previous[state.currentStep],
      continueButtonOptions: null,
      backButtonOptions: null,
    })),

  setContinueButtonOptions: (options) =>
    set({ continueButtonOptions: options }),

  setBackButtonOptions: (options) => set({ backButtonOptions: options }),

  // Payment Actions
  setFlashCashBalances: (balances) => set({ flashCashBalances: balances }),

  setIsTopupCredit: (isTopup) => set({ isTopupCredit: isTopup }),

  setCreditDays: (days) => set({ creditDays: days }),

  // Reset Action
  resetStore: () => set({ ...initialState }),
}))

if (processEnv.BUILD_ENV === 'development') {
  useNewOrderStore.subscribe((state) => {
    console.log('state updated (useNewOrderStoreNew)', state)
  })
}
