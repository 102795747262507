import { LinkButton } from '@flash-tecnologia/hros-web-ui-v2'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { CreditTypeEnum } from 'src/components/orders/components/pre-order-checkout-modal/content/credit-type'
import { OrderMethodEnum } from 'src/components/orders/components/pre-order-checkout-modal/content/order-method'

import { StepNavigationButton } from '../components/step-navigation-button'
import { EmployeeSelection } from '../steps/employee-selection'
import { OrderCompletion } from '../steps/order-completion'
import { PaymentSetup } from '../steps/payment-setup'
import { ReviewAndConfirm } from '../steps/review-and-confirm'
import { Step, useNewOrderStore } from '../store/use-new-order-store'

const stepMap = {
  [Step.EMPLOYEE_SELECTION]: EmployeeSelection,
  [Step.PAYMENT_SETUP]: PaymentSetup,
  [Step.REVIEW_DETAILS]: ReviewAndConfirm,
  [Step.ORDER_COMPLETED]: OrderCompletion,
}

const stepQueryMap = {
  [Step.EMPLOYEE_SELECTION]: 'employee-selection',
  [Step.PAYMENT_SETUP]: 'payment-setup',
  [Step.REVIEW_DETAILS]: 'review',
  [Step.ORDER_COMPLETED]: 'confirmation',
}

function updateStepAsFirstParam(params: URLSearchParams, currentStep: Step) {
  const newParams = new URLSearchParams(params)
  newParams.delete('step')

  const updatedParams = new URLSearchParams()
  updatedParams.append('step', stepQueryMap[currentStep])

  newParams.forEach((value, key) => {
    updatedParams.append(key, value)
  })

  return updatedParams
}

export function useNewOrderPage() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [params, setSearchParams] = useSearchParams()
  const {
    resetStore,
    backButtonOptions,
    continueButtonOptions,
    currentStep,
    setIsTopupCredit,
  } = useNewOrderStore()

  const steps = useMemo(
    () => [
      t('newOrder.steps.employeeSelection'),
      t('newOrder.steps.paymentSetup'),
      t('newOrder.steps.reviewDetails'),
      t('newOrder.steps.orderCompleted'),
    ],
    [t],
  )

  const breadcrumb = useMemo(
    () => [
      {
        label: t('newOrder.breadcrumb.orders'),
        route: '/orders',
      },
      {
        label: t('newOrder.breadcrumb.makeOrder'),
      },
    ],
    [t],
  )

  const orderParams = useMemo(() => {
    return {
      creditType: params.get('credit-type'),
      orderMethod: params.get('order-method'),
    }
  }, [params])

  const handleCancel = useCallback(() => {
    resetStore()
    navigate('/orders')
  }, [resetStore, navigate])

  const renderStartActions = useMemo(
    () =>
      currentStep !== Step.ORDER_COMPLETED && (
        <LinkButton
          key="cancel-button"
          variant="neutral"
          onClick={handleCancel}
        >
          {t('newOrder.buttons.cancel')}
        </LinkButton>
      ),
    [currentStep, handleCancel, t],
  )

  const renderEndActions = useMemo(
    () => [
      backButtonOptions && (
        <StepNavigationButton
          key="back-button"
          variant="secondary"
          {...backButtonOptions}
        />
      ),
      continueButtonOptions && (
        <StepNavigationButton
          key="next-button"
          variant="primary"
          {...continueButtonOptions}
        />
      ),
    ],
    [continueButtonOptions, backButtonOptions],
  )

  useEffect(() => {
    const { creditType, orderMethod } = orderParams

    if (
      creditType !== CreditTypeEnum.ACCUMULATIVE &&
      creditType !== CreditTypeEnum.TOPUP &&
      orderMethod !== OrderMethodEnum.PLATFORM
    ) {
      navigate('/orders', { replace: true })
    }
  }, [navigate, orderParams])

  useEffect(() => {
    if (orderParams.creditType) {
      setIsTopupCredit(orderParams.creditType === CreditTypeEnum.TOPUP)
    }
  }, [orderParams, setIsTopupCredit])

  useEffect(() => {
    const currentUrlStep = params.get('step')

    if (stepQueryMap[currentStep] !== currentUrlStep) {
      const updatedParams = updateStepAsFirstParam(params, currentStep)

      setSearchParams(updatedParams)
    }
  }, [currentStep, params, setSearchParams])

  return {
    activeStep: currentStep,
    breadcrumb,
    steps,
    startActions: [renderStartActions],
    endActions: renderEndActions,
    searchParams: orderParams,
    setSearchParams,
    StepRender: stepMap[currentStep],
  }
}
