import { useRegisterRoutes } from '@flash-tecnologia/hros-web-utility'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom'
import { ErrorBoundary } from 'src/components/error-boundary'

import { CheckoutRouter } from './checkout/checkout-router'
import { OpenRoutesGuard } from './open-routes-guard'
import { OrdersRouter } from './orders/orders-router'

const PROJECT_PREFIX = '/benefit-order'
const orderRoute = `${PROJECT_PREFIX}/checkout`

const AppRouter = () => {
  useRegisterRoutes([
    {
      path: `${PROJECT_PREFIX}/order`,
      hideHeader: true,
    },
    {
      path: `${PROJECT_PREFIX}/orders/new`,
      hideHeader: true,
    },
    {
      path: orderRoute,
      hideHeader: true,
    },
    {
      path: `${orderRoute}/:orderId`,
      hideHeader: true,
    },
    {
      path: `${orderRoute}/:orderId/review`,
      hideHeader: true,
    },
    {
      path: `${orderRoute}/:orderId/confirmation`,
      hideHeader: true,
    },
  ])

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route errorElement={<ErrorBoundary />} element={<OpenRoutesGuard />}>
        {CheckoutRouter}
        {OrdersRouter}
      </Route>,
    ),
    { basename: PROJECT_PREFIX },
  )

  return <RouterProvider router={router} />
}
export default AppRouter
