import { LinkButton } from '@flash-tecnologia/hros-web-ui-v2'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { StepNavigationButton } from 'src/components/step-navigation-button'

import { AddEmployeesForm } from '../steps/add-employees'
import { FlashSpreadsheet } from '../steps/flash-spreadsheet'
import { ProcessingStatusOrder } from '../steps/processing-order-status'
import {
  Step,
  useOrderBySpreadsheetStore,
} from '../store/use-order-by-spreadsheet-store'

const stepMap = {
  [Step.FLASH_SPREADSHEET]: FlashSpreadsheet,
  [Step.ADD_EMPLOYEES]: AddEmployeesForm,
  [Step.PROCESSING_ORDER]: ProcessingStatusOrder,
}

export function useNewSpreadsheetOrderPage() {
  const navigate = useNavigate()
  const { resetStore, backButtonOptions, continueButtonOptions, currentStep } =
    useOrderBySpreadsheetStore()

  const breadcrumb = useMemo(
    () => [
      {
        label: 'Pedidos de Benefícios',
        route: '/orders',
      },
      {
        label: 'Fazer novo pedido',
      },
    ],
    [],
  )

  const handleCancel = useCallback(() => {
    resetStore()
    navigate('/orders')
  }, [resetStore, navigate])

  const renderStartActions = useMemo(
    () => (
      <LinkButton key="cancel-button" variant="neutral" onClick={handleCancel}>
        Cancelar
      </LinkButton>
    ),
    [handleCancel],
  )

  const renderEndActions = useMemo(
    () => [
      backButtonOptions && (
        <StepNavigationButton
          key="back-button"
          variant="secondary"
          {...backButtonOptions}
        />
      ),
      continueButtonOptions && (
        <StepNavigationButton
          key="next-button"
          variant="primary"
          {...continueButtonOptions}
        />
      ),
    ],
    [continueButtonOptions, backButtonOptions],
  )

  return {
    activeStep: currentStep,
    breadcrumb,
    startActions: [renderStartActions],
    endActions: renderEndActions,
    StepRender: stepMap[currentStep],
  }
}
