import { CreditTypeEnum } from 'src/domain/orders/pages/orders-page/components/pre-order-checkout-modal/components/content/credit-type'

import { Benefit, Employee } from '../../employee-selection/types'

export const filterByCreditType = (
  employees: Employee[],
  creditType: CreditTypeEnum,
): Employee[] => {
  const shouldIncludeBenefit = (benefit: Benefit) =>
    creditType === CreditTypeEnum.ACCUMULATIVE
      ? !benefit.topupDepositEnabled
      : benefit.topupDepositEnabled

  return employees.map((employee) => ({
    ...employee,
    benefits: employee.benefits.filter(shouldIncludeBenefit),
  }))
}
