import { ShapeIcon } from '@flash-tecnologia/hros-web-ui-v2'
import { Box } from 'src/components/box'
import styled from 'styled-components'

export const ProgressContainerStyled = styled.div`
  width: 50%;
  margin-top: ${({ theme }) => theme.spacings.xs1};
`
export const DownloadContainerStyled = styled.div`
  margin-top: ${({ theme }) => theme.spacings.s};
`
export const ActionsContainerStyled = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings.xs};
  margin-top: ${({ theme }) => theme.spacings.l};
`
export const ShapeIconStyled = styled(ShapeIcon)`
  margin-bottom: ${({ theme }) => theme.spacings.xs4};
`

export const TitleDescriptionContainerStyled = styled(Box)`
  gap: ${({ theme }) => theme.spacings.xs5};
  margin-bottom: ${({ theme }) => theme.spacings.s};
`

export const DescriptionContainerStyled = styled(Box)`
  max-width: 434px;
`
