import { useContext } from 'react'

import { EmployeeSelectionContext } from '../context'

export function useEmployeeSelection() {
  const context = useContext(EmployeeSelectionContext)

  if (!context) {
    throw new Error(
      'useEmployeeSelectionContext must be used within an EmployeeSelectionProvider',
    )
  }

  return context
}
