import {
  DownloadButton,
  Dropzone,
  Skeleton,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2'
import { useTranslation } from 'react-i18next'
import { Grid } from 'src/components/grid'
import { RadioCard } from 'src/components/radio-card'
import { RadioCardTextTypeEnum } from 'src/components/radio-card/radio-card-text/radio-card-text'
import { useTheme } from 'styled-components'

import { GridContainerStyled, TitleStyled } from '../styles'
import { SpreadsheetIntructions } from './components/spreadsheet-instructions'
import { useFlashSpreadsheet } from './hooks/use-flash-spreadsheet'

export function FlashSpreadsheet() {
  const theme = useTheme()
  const { t } = useTranslation()
  const {
    handleFileChange,
    handleFileRemove,
    handleOnlyOrder,
    isLoadingSpreadsheetPath,
    isOnlyOrder,
    spreadsheetPath,
  } = useFlashSpreadsheet()

  return (
    <>
      <TitleStyled variant="headline6" weight={700} color="neutral.20">
        {t('newOrder.titleSpreadsheet')}
      </TitleStyled>
      <GridContainerStyled
        display="grid"
        flexDirection="row"
        gap={theme.spacings.xs}
      >
        <Grid.Item flexDirection="column">
          <TitleStyled variant="headline7" weight={700} color="neutral.20">
            {t('newOrder.steps.flashSpreadsheet.title')}
          </TitleStyled>
          <Typography variant="body3" weight={400} color="neutral.40">
            {t('newOrder.steps.flashSpreadsheet.description')}
          </Typography>
        </Grid.Item>
        <Grid.Container flexDirection="column" gap={theme.spacings.m}>
          {(isLoadingSpreadsheetPath || !spreadsheetPath) && (
            <Skeleton width="100%" height={278} variant="rounded" />
          )}

          {!isLoadingSpreadsheetPath && spreadsheetPath && (
            <Grid.CardGrid flexDirection="column" gap={theme.spacings.xs}>
              <div>
                <Typography variant="headline8" weight={700} color="neutral.20">
                  {t('newOrder.titleSpreadsheet')}
                </Typography>
                <Typography variant="body3" weight={400} color="neutral.40">
                  {t('newOrder.flashSpreadsheet.description')}
                </Typography>
              </div>

              <Grid.Item width="fit-content">
                <DownloadButton
                  title={t('newOrder.flashSpreadsheet.download')}
                  fileName="pedido-beneficios-flash.xlsx"
                  filePath={spreadsheetPath}
                  docIconSize={32}
                />
              </Grid.Item>
              <SpreadsheetIntructions />
            </Grid.CardGrid>
          )}

          <Grid.CardGrid flexDirection="column" gap={theme.spacings.xs}>
            <div>
              <Typography variant="headline8" weight={700} color="neutral.30">
                {t('newOrder.flashSpreadsheet.addEmployees.title')}
              </Typography>
              <Typography variant="body3" weight={400} color="neutral.50">
                {t('newOrder.flashSpreadsheet.addEmployees.description')}
              </Typography>
            </div>
            <Grid.Item
              display="flex"
              gap={theme.spacings.xs}
              flexDirection="row"
            >
              <RadioCard.Root
                checked={isOnlyOrder}
                onClick={handleOnlyOrder}
                disabled={false}
              >
                <RadioCard.Radio checked={isOnlyOrder} disabled={false} />
                <Grid.Container flexDirection="column" minWidth="auto">
                  <RadioCard.Text
                    checked={isOnlyOrder}
                    disabled={false}
                    text={t(
                      'newOrder.flashSpreadsheet.addEmployees.firstCard.title',
                    )}
                    textVariant="body3"
                    type={RadioCardTextTypeEnum.TITLE}
                  />
                  <RadioCard.Text
                    checked={isOnlyOrder}
                    disabled={false}
                    text={t(
                      'newOrder.flashSpreadsheet.addEmployees.firstCard.description',
                    )}
                    textVariant="body4"
                  />
                </Grid.Container>
              </RadioCard.Root>
              <RadioCard.Root
                checked={!isOnlyOrder}
                onClick={handleOnlyOrder}
                disabled={false}
              >
                <RadioCard.Radio checked={!isOnlyOrder} disabled={false} />
                <Grid.Container flexDirection="column" minWidth="auto">
                  <RadioCard.Text
                    checked={!isOnlyOrder}
                    disabled={false}
                    text={t(
                      'newOrder.flashSpreadsheet.addEmployees.secondCard.title',
                    )}
                    textVariant="body3"
                    type={RadioCardTextTypeEnum.TITLE}
                  />
                  <RadioCard.Text
                    checked={!isOnlyOrder}
                    disabled={false}
                    text={t(
                      'newOrder.flashSpreadsheet.addEmployees.secondCard.description',
                    )}
                    textVariant="body4"
                  />
                </Grid.Container>
              </RadioCard.Root>
            </Grid.Item>
          </Grid.CardGrid>

          <Grid.CardGrid flexDirection="column" gap={theme.spacings.xs}>
            <div>
              <Typography variant="headline8" weight={700} color="neutral.20">
                {t('newOrder.flashSpreadsheet.uploadFile.title')}
              </Typography>
              <Typography variant="body3" weight={400} color="neutral.40">
                {t('newOrder.flashSpreadsheet.uploadFile.description')}
              </Typography>
            </div>
            <Grid.Item
              display="flex"
              gap={theme.spacings.xs}
              flexDirection="row"
            >
              <Dropzone
                accept={['xls', 'xlsx', 'csv', 'txt']}
                onChange={(files) => handleFileChange(files)}
                onRemove={handleFileRemove}
                title={t('newOrder.flashSpreadsheet.uploadFile.uploadText')}
              />
            </Grid.Item>
          </Grid.CardGrid>
        </Grid.Container>
      </GridContainerStyled>
    </>
  )
}
