import {
  Button,
  Checkbox,
  Icons,
  LinkButton,
  Table,
  Tag,
  Tooltip,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'

import { OrderSummary } from '../../components/order-summary'
import { VirtualList } from './components/virtual-list'
import { EmployeeSelectionProvider } from './context'
import { useEmployeeSelection } from './hooks/use-employee-selection'
import {
  EmployeeSelectionContainerStyled,
  HeaderWrapperStyled,
  SearchWrapperStyled,
  TableWrapperStyled,
} from './styles'

function EmployeeSelectionContent() {
  const { t } = useTranslation()
  const theme = useTheme()
  const {
    areAllSelected,
    isHeaderIndeterminate,
    toggleSelectAll,
    selectedEmployees,
    totalEmployee,
    total,
    setSearchTerm,
    tableHeaderGroups,
    groups,
    pagination,
    setPagination,
    isLoading,
    handleEditGroup,
    visibleTableRef,
  } = useEmployeeSelection()

  return (
    <EmployeeSelectionContainerStyled>
      <TableWrapperStyled ref={visibleTableRef}>
        <Table.Root>
          <HeaderWrapperStyled>
            <Typography
              variant="headline8"
              weight={700}
              variantColor={theme.colors.neutral20}
            >
              {t('newOrder.employeeSelection.title')}
            </Typography>

            <Typography variant="body3" variantColor={theme.colors.neutral40}>
              {t('newOrder.employeeSelection.subtitle')}
            </Typography>
          </HeaderWrapperStyled>

          <SearchWrapperStyled>
            <Table.Search
              label={t('newOrder.employeeSelection.search.label')}
              disabled={isLoading}
              onChange={(e) => setSearchTerm(e.target.value)}
            />

            <div className="actions">
              <Tooltip
                placement="bottom-end"
                title={t('newOrder.employeeSelection.editGroup.tooltip')}
              >
                <Button
                  variant="secondary"
                  variantType="neutral"
                  onClick={handleEditGroup}
                >
                  {t('newOrder.employeeSelection.editGroup.text')}{' '}
                  <Icons name="IconUsers" />
                </Button>
              </Tooltip>
            </div>
          </SearchWrapperStyled>

          <Table.Grid.Root loading={isLoading}>
            {areAllSelected || isHeaderIndeterminate ? (
              <tr
                className="data-grid-table-header-bulk-actions-container"
                role="row"
              >
                <th className="data-grid-table-header-bulk-actions">
                  <Checkbox
                    checked={areAllSelected}
                    indeterminate={isHeaderIndeterminate}
                    onChange={toggleSelectAll}
                    aria-label="column row checkbox"
                  />
                </th>

                <th className="data-grid-table-header-bulk-actions">
                  <Tag variant="primary" as="label">
                    {t('newOrder.employeeSelection.tag.selectedEmployee', {
                      selectedEmployee: selectedEmployees.length,
                      totalEmployee,
                    })}
                  </Tag>
                </th>

                <th className="data-grid-table-header-bulk-actions">
                  <LinkButton variant="neutral" href="/simple-assignment">
                    <Icons name="IconCoin" size={20} />{' '}
                    {t(
                      'newOrder.employeeSelection.linkButton.changeBenefitValues',
                    )}
                  </LinkButton>
                </th>
              </tr>
            ) : (
              <Table.Grid.Header getHeaderGroups={tableHeaderGroups} />
            )}

            {groups.map((group) => (
              <Fragment key={group.id}>
                <Table.Grid.Row row={group} />

                {group.getIsExpanded() && (
                  <tr>
                    <td colSpan={group.getVisibleCells().length}>
                      <VirtualList employees={group.original.employees} />
                    </td>
                  </tr>
                )}
              </Fragment>
            ))}
          </Table.Grid.Root>

          <Table.Pagination
            count={total}
            onPaginationChange={({ pageSize, pageNumber }) =>
              setPagination({ ...pagination, pageSize, pageNumber })
            }
            pagination={pagination}
          />
        </Table.Root>
      </TableWrapperStyled>

      <OrderSummary />
    </EmployeeSelectionContainerStyled>
  )
}

export function EmployeeSelection() {
  return (
    <EmployeeSelectionProvider>
      <EmployeeSelectionContent />
    </EmployeeSelectionProvider>
  )
}
