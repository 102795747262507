import { ComponentProps } from 'react'

import { BaseBoxStyled, BoxProps } from './styles'

function Box(props: ComponentProps<typeof BaseBoxStyled>) {
  return <BaseBoxStyled {...props} />
}

function Bordered(props: BoxProps) {
  return (
    <Box
      gap="xs3"
      padding="xs3 xs2"
      borderWidth="xs2"
      borderColor="neutral.90"
      radius="m"
      {...props}
    />
  )
}

Box.Bordered = Bordered

export { Box }
