import { Checkbox } from '@flash-tecnologia/hros-web-ui-v2'
import { Emoji } from 'src/components/emoji'

import { BenefitAssignment } from '../../store/use-edit-assignments-modal-store'
import { TextField } from './components/text-field'
import { useBenefitEdit } from './hooks/use-benefit-edit'
import { BenefitEditWrapperStyled, TypographyStyled } from './styles'

type BenefitEditProps = {
  benefit: BenefitAssignment
}

export function BenefitEdit({ benefit }: BenefitEditProps) {
  const {
    isFieldEnabled,
    toggleFieldState,
    valueInCurrency,
    handleInputChange,
    mode,
  } = useBenefitEdit({ benefit })

  return (
    <BenefitEditWrapperStyled>
      <div>
        <Checkbox checked={isFieldEnabled} onChange={toggleFieldState} />

        <div>
          <Emoji imgSrc={benefit.emoji} width={40} />

          <TypographyStyled variant="body3" weight={600} color="neutral.30">
            {benefit.name}
          </TypographyStyled>
        </div>
      </div>

      <TextField
        value={valueInCurrency}
        onChange={handleInputChange}
        depositTimeUnit={benefit.depositTimeUnit}
        isFieldEnabled={isFieldEnabled}
        mode={mode}
      />
    </BenefitEditWrapperStyled>
  )
}
