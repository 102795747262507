import { ModalOTP } from '@flash-hros/hocs'
import { NotificationCard, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { t } from 'i18next'
import { Grid } from 'src/components/grid'
import { FlashCashBalances } from 'src/domain/checkout/presenters/checkout-method/components/flash-cash/flash-cash-balances'
import { OrderConfirmLoading } from 'src/domain/checkout/presenters/order-review/order-confirm-loading'
import { PaymentMethodIcon } from 'src/domain/checkout/presenters/payment-method/payment-method-icon'
import { PaymentMethodEnum } from 'src/enums/paymentMethodEnum'
import { useTheme } from 'styled-components'

import { ModalEmployeesOrderSummary } from '../../components/modal-employees-order-summary'
import { OrderReviewSummary } from './components/order-review-summary'
import { useReviewDetails } from './hooks/use-review-details'

export function ReviewAndConfirm() {
  const theme = useTheme()
  const {
    isTopupCredit,
    otpRef,
    isConfirmingPixOrder,
    showEmployeesOrderModal,
    setShowEmployeesOrderModal,
    toggleEmployeesOrderModal,
    order,
    orderSummary,
    flashCashBalances,
  } = useReviewDetails()

  return (
    <>
      <ModalOTP
        ref={otpRef}
        subtitle={t('order.confirmation.confirmCodeSubtitle')}
      />

      <ModalEmployeesOrderSummary
        isOpen={showEmployeesOrderModal}
        onClose={toggleEmployeesOrderModal}
        orderId={order?.id}
      />

      <Grid.Container
        container
        gridColumn="1 / 1"
        rowGap={{ xs: theme.spacings.s }}
      >
        <Grid.SideGrid item xs={12} xl={3} pr={{ xl: theme.spacings.s }}>
          <Typography variant="headline7" color="neutral.20">
            {t(`order.review.sideGrid.title`)}
          </Typography>
          <Typography variant="body3" color="neutral.30">
            {t(`order.review.sideGrid.subtitle`)}
          </Typography>
        </Grid.SideGrid>
        <Grid.CardGrid item xs={12} xl={9}>
          {!isConfirmingPixOrder && (
            <Typography variant="headline8" color="neutral.30">
              {isTopupCredit
                ? t('order.review.cardGrid.titleTopup')
                : t(`order.review.cardGrid.title`)}
            </Typography>
          )}
          <Grid.Item container direction={'row'} gap={theme.spacings.s}>
            {isConfirmingPixOrder ? (
              <OrderConfirmLoading />
            ) : (
              <OrderReviewSummary
                setShowEmployeesOrderModal={setShowEmployeesOrderModal}
              />
            )}

            <Grid.Item xs={12} xl>
              <Grid.Item container gap={theme.spacings.s} direction={'column'}>
                <Typography variant="headline8" color="neutral.20">
                  {t('order.review.title')}
                </Typography>
                <Grid.CardGrid
                  border={`${theme.borders.width.xs2} solid ${theme.colors.neutral[90]}`}
                  gap={theme.spacings.xs3}
                  p={theme.spacings.s}
                >
                  <PaymentMethodIcon
                    paymentMethod={order?.paymentMethod as PaymentMethodEnum}
                  />
                  <Grid.Item container gap={theme.spacings.xs5}>
                    <Typography variant="body3" color="neutral.20" weight={700}>
                      {t(
                        `order.review.cardGrid.paymentMethod.${
                          order?.paymentMethod as PaymentMethodEnum
                        }.name`,
                      )}
                    </Typography>
                    <Grid.Item container gap={theme.spacings.xs1}>
                      <Typography variant="body4" color="neutral.30">
                        {t(
                          `order.review.cardGrid.paymentMethod.${
                            order?.paymentMethod as PaymentMethodEnum
                          }.description`,
                        )}
                      </Typography>

                      {order?.paymentMethod === PaymentMethodEnum.BILLET && (
                        <Typography variant="body4" color="neutral.30">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: t(
                                `order.review.cardGrid.paymentMethod.${
                                  order?.paymentMethod as PaymentMethodEnum
                                }.paymentConditions`,
                              ),
                            }}
                          />
                        </Typography>
                      )}
                    </Grid.Item>
                  </Grid.Item>
                </Grid.CardGrid>
                {order?.paymentMethod === 'BALANCE' && flashCashBalances && (
                  <FlashCashBalances
                    balances={flashCashBalances}
                    totalAmount={orderSummary?.totalAmount ?? 0}
                  />
                )}
                <Grid.Item
                  container
                  direction={'column'}
                  gap={theme.spacings.xs4}
                >
                  <Typography
                    variant="headline8"
                    color="neutral.30"
                    weight={700}
                  >
                    {t(
                      `order.review.cardGrid.totalAmount.${
                        isTopupCredit ? 'titleTopup' : 'title'
                      }`,
                    )}
                  </Typography>
                  <Typography
                    style={{
                      color: theme.colors.secondary[50],
                    }}
                    variant="headline6"
                  >
                    {t(`order.review.cardGrid.totalAmount.value`, {
                      value:
                        Number(orderSummary?.totalAmount) +
                        Number(orderSummary?.totalFee),
                    })}
                  </Typography>

                  {isTopupCredit && (
                    <NotificationCard.Root
                      type="info"
                      variant="contained"
                      isVertical
                    >
                      <NotificationCard.WrapperIconWithText>
                        <NotificationCard.Icon
                          size={16}
                          iconName="IconAlertCircle"
                        />

                        <NotificationCard.Title>
                          {t('order.review.cardGrid.infoTopup.title')}
                        </NotificationCard.Title>
                      </NotificationCard.WrapperIconWithText>

                      <NotificationCard.Subtitle>
                        {t('order.review.cardGrid.infoTopup.description')}
                      </NotificationCard.Subtitle>
                    </NotificationCard.Root>
                  )}
                </Grid.Item>
              </Grid.Item>
            </Grid.Item>
          </Grid.Item>
        </Grid.CardGrid>
      </Grid.Container>
    </>
  )
}
