import { OrderStatusEnum as OrderStatusTagEnum } from 'src/components/order-confirmation-card/components/header/components/tags/order-status-tag'
import { DepositStatusEnum } from 'src/enums/depositStatusEnum'
import { OrderStatusEnum } from 'src/enums/orderStatusEnum'
import { PaymentMethodEnum } from 'src/enums/paymentMethodEnum'

import { LocaleKeys } from './localeKeys'

const translations: LocaleKeys = {
  translations: {
    errorBoundary: {
      title: 'An unexpected error has occurred',
      description:
        'We could not find the page you were looking for. We apologize for the inconvenience.',
      button: 'Return to the home page',
    },
    optionalTag: 'Optional',
    modalAlertConfirm: {
      attention: 'Attention',
      btnCancel: 'Cancel',
      btnConfirm: 'Confirm',
    },
    modalCancel: {
      attention: 'Attention',
      btnBack: 'Close',
      btnCancel: 'Yes, cancel',
      order: {
        message:
          'Are you sure you want to cancel order #{{orderId}} worth {{amount, currency(BRL)}}?',
        alertMessage: `This action cannot be undone. Orders with the status of "scheduled availability" will have the amounts refunded to the company's Flash Cash.`,
      },
      deposit: {
        message: 'Are you sure you want to cancel the deposit of {{name}}?',
        alertMessage: `After the deposit is canceled, the person will no longer receive the requested credit. Deposits with the status of "scheduled availability" will have their values converted into Flash Cash benefits balance.`,
      },
    },
    modalWarn: {
      attention: 'Attention',
      btnConfirm: 'Ok, understood',
    },
    modalEmployeesList: {
      title: 'Employees list',
      description: 'Check the list of selected employees for this order',
      tableName: 'Employees list',
      searchPlaceholder: 'Search by name or document',
      tableColumns: {
        people: 'Person',
        company: 'Company',
        amount: 'Amount',
        unitValue: '{{value, currency(BRL)}}',
      },
    },
    order: {
      errors: {
        generic: 'Generating order error',
        reportError: 'Generating report error',
        emptySelection: 'Please reselect employees and try again',
        emptyPaymentMethod: 'Select a payment method',
        emptyDepositDate: 'Select deposit date',
        balance: 'Insufficient balance',
        balanceDescription:
          'There is not enough Flash Cash balance to make the order. Please insert more balance or change the payment method to continue.',
        token: 'Invalid token',
        flashCash: {
          getBalanceTitle: 'Error getting flash cash balance',
          getBalanceDescription:
            'An error occurred while trying to get the Flash Cash balance, please try again later.',
        },
        topupSchedule: 'Generating topup schedule error',
        pixForVirtualBenefits:
          'It is not possible to pay virtual benefits via Pix',
      },
      pageTitle: 'Order of benefits through the platform',
      paymentMethods: {
        header: {
          title: 'Choose the payment method and the availability date',
          titleTopup: 'Benefits with Smart Top-up',
          // subtitle: 'Select the payment method between Billet or Flash Cash.',
          subtitle: 'Select the payment method.',
        },
        title: 'Payment method',
        [PaymentMethodEnum.BILLET]: 'Billet',
        [PaymentMethodEnum.PIX]: 'Pix',
        [PaymentMethodEnum.FLASH_CASH]: 'Flash Cash',
        topupWarning: {
          title: 'Important',
          subtitle:
            'Benefits orders with smart top-up can only be paid with Flash Cash.',
        },
      },
      checkoutDates: {
        availabilityDate: {
          title: 'Benefit availability date',
          subtitle:
            'Determine the date your employees will receive the benefits.',
          depositDateInput: {
            label: 'Choose the date of availability of the benefits',
            inputPlaceholder: 'Availability date',
            format: 'DD/MM/AAAA',
          },
          pixDueDateInput: {
            label: 'Pix code due date',
            inputPlaceholder: 'Pix code due date',
          },
          billetDueDateInput: {
            label: 'Billet due date',
            inputPlaceholder: 'Billet due date',
          },
        },
        cutoffDate: {
          title: 'Cutoff Date and Time for Setting the Top-up Amount',
          subtitle:
            "On this day and time, the system will read the employee's balance and calculate the top-up amount. Example: if the person's balance is 180 reais per month and by the chosen date and time they have only used 160 reais, only these 160 reais will be spent on the order, complementing the balance.",
          cutoffDateInput: {
            inputPlaceholder: 'Choose the cutoff date',
            format: 'DD/MM/AAAA',
          },
          cutoffTimeInput: {
            inputPlaceholder: 'Choose the cutoff time',
          },
        },
      },
      receiptDescription: {
        label: 'Additional description on the invoice',
        helperText: 'Maximum of {{max}} characters',
        placeholder: 'Add an additional description to the order invoice',
      },
      balances: {
        title: 'Flash Cash wallet balance',
        plastic: 'Card Benefits',
        virtual: 'Virtual Benefits',
        value: '{{value, currency(BRL)}}',
      },
      checkout: {
        orderSummary: {
          title: 'Order summary',
          employeesCount: 'Order for {{employeesCount}} people',
          orderEmployeesButton: 'Employees list',
          fees: {
            value: '{{value, currency(BRL)}}',
            title: 'Fees',
            accountsFee: 'Accounts maintenance',
          },
          discounts: {
            value: '{{value, currency(BRL)}}',
            title: 'Discounts',
            reimbursementsFee: 'Fees reimbursement',
          },
          benefits: {
            value: '{{value, currency(BRL)}}',
            title: 'Flash Benefits',
          },
          topup: {
            value: '{{value, currency(BRL)}}',
            title: 'Benefits with Smart Top-up',
          },
          totalAmount: {
            value: '{{value, currency(BRL)}}',
            title: 'Total of order items',
            plastic: 'Card Benefits',
            virtual: 'Virtual Benefits',
            tooltip:
              'The amount of the order items is the total amount of the benefits that will be available to your employees.',
            total: 'Total benefits',
          },
          totalPartial: {
            tooltip:
              'Final amount will be displayed in the order summary stage, considering possible discounts and fees',
          },
          total: 'Partial Total',
          value: 'Partial Value',
          employeesSelectedCount_one: '{{count}} selected',
          employeesSelectedCount_other: '{{count}} selected',
          viewEmployeeSelected: 'View selection',
          itens_one: '{{count}} item',
          itens_other: '{{count}} items',
        },
      },
      statusTag: {
        [OrderStatusTagEnum.PAID]: 'Paid',
        [OrderStatusTagEnum.PENDING]: 'Waiting for payment',
      },
      confirmation: {
        sideGrid: {
          [PaymentMethodEnum.BILLET]: {
            title: 'Order placed',
            subtitle:
              'Your order has been placed and will be processed after payment of the billet',
          },
          [PaymentMethodEnum.PIX]: {
            title: 'Order placed',
            subtitle: 'Make the transfer by pix for us to process your order',
          },
          [PaymentMethodEnum.FLASH_CASH]: {
            title: 'Order completed',
            subtitle:
              'Your order has been processed with your Flash Cash credits',
          },
        },
        cardGrid: {
          title: 'Order #{{code}}',
          subtitle: {
            [PaymentMethodEnum.BILLET]:
              'The billet will be sent to the email <strong>{{email}}</strong>',
            [PaymentMethodEnum.PIX]:
              'The pix code will be sent to the email <strong>{{email}}</strong>',
            [PaymentMethodEnum.FLASH_CASH]:
              'Flash Cash transaction completed successfully.',
          },
          infos: {
            amount: 'Order value',
            dueDate: {
              [PaymentMethodEnum.BILLET]: 'Billet due date',
              [PaymentMethodEnum.PIX]: 'Pix code due date',
            },
            depositDate: 'Availability',
          },
          buttons: {
            links: {
              deposits: 'Go to deposits',
              [PaymentMethodEnum.BILLET]: 'Go to billets',
              [PaymentMethodEnum.PIX]: 'Go to Pix area',
            },
            generateReport: 'Generate order report',
          },
          reminder: {
            title: 'Remember',
            subtitle: {
              prefix: 'It is possible to cancel deposits for this order until',
              sufix: {
                [PaymentMethodEnum.BILLET]: 'through the Billets page',
                [PaymentMethodEnum.PIX]: 'through the Pix page',
                [PaymentMethodEnum.FLASH_CASH]: 'through the Flash Cash page',
              },
            },
          },
        },
        confirmCodeSubtitle:
          'To place your order using your benefit Flash Cash balance, you must enter the token sent to your email',
        pixInfo: {
          title: 'Pay with Pix',
          description:
            'Pay on any day and time until the due date. Orders placed for the same day will be deposited within two hours after payment',
          subtitle: 'You can also pay using the code “Pix Copia e cola”',
          copyButton: 'Copy Pix code',
          stepsTitle: 'Step-by-step for payment via QR-CODE',
          steps: [
            'In the bank or financial institution app, enter the Pix area',
            'Choose the option to pay with QR Code and scan the code below',
            'Confirm the information and complete the payment',
          ],
        },
      },
      review: {
        title: 'Payment method',
        sideGrid: {
          title: 'Review order details',
          subtitle:
            'Take the opportunity to check the values and benefits that are part of your order.',
        },
        cardGrid: {
          title: 'Order summary',
          titleTopup: 'Summary of the benefit request with smart recharge',
          orderSummary: {
            title: 'Order items',
            depositDate: { title: 'Availability of benefits' },
            employeesCount: 'People covered',
          },
          paymentMethod: {
            [PaymentMethodEnum.BILLET]: {
              name: 'Bank slip',
              description:
                'Payment can be made through a bank account or agency',
              paymentConditions:
                'Payments made before 10:00 p.m. (Brasília time) are cleared within 1 business day. <br />Payments made after 10:00 p.m. (Brasília time) are cleared within 2 business days.',
            },
            [PaymentMethodEnum.PIX]: {
              name: 'Pix',
              description:
                'Pay with Pix any day and any time, payment is instant, practical and can be done in a few seconds. It is fast and secure.',
            },
            [PaymentMethodEnum.FLASH_CASH]: {
              name: 'Flash Cash benefits',
              description:
                'Make real-time orders for people in the company. Have money in your Flash Cash account to make deposits whenever you want.',
            },
          },
          totalAmount: {
            title: 'Total order',
            titleTopup: 'Maximum order value',
            value: '{{value, currency(BRL)}}',
          },
          infoTopup: {
            title: 'Maximum order value',
            description:
              'This is an estimated amount that considers the maximum this order could cost. Only after the cut-off date will we have the real amount to be paid, considering how much was saved with smart recharge.',
          },
        },
        loading: {
          title: 'Finalizing the items in your order in Flash',
          subtitle: 'Please wait',
        },
      },
      flashCashTopUpModal: {
        btnCancel: 'Not Now',
        btnConfirm: 'Top Up',
        title: 'Insufficient Balance!',
        message:
          'Top up your Flash Cash balance now to ensure the completion of your order.',
        warning:
          'If the top-up is not completed by {{cutOffDate}}, your order will not be processed.',
      },
    },
    orders: {
      headerPage: {
        title: 'Orders placed',
        subtitle: 'Track company benefit orders and their availability status',
      },
      content: {
        headerContent: {
          title: 'Company benefits orders',
          textTag: 'items',
        },
        emptyState: {
          title: "You haven't made benefit orders yet",
          subtitle: 'New company orders will appear here',
          buttonLink: 'Discover the orders page',
        },
        table: {
          columns: {
            code: 'Code',
            requested: 'Requested date',
            paymentMethod: 'Payment method',
            value: 'Value',
            creditDate: 'Credit date',
            status: 'Status',
          },
          cells: {
            paymentMethod: {
              [PaymentMethodEnum.BILLET]: 'Billet',
              [PaymentMethodEnum.PIX]: 'Pix',
              [PaymentMethodEnum.FLASH_CASH]: 'Flash Cash',
            },
            value: '{{value, currency(BRL)}}',
            status: {
              [OrderStatusEnum.AVAILABLE]: 'Available',
              [OrderStatusEnum.BILLED]: 'Awaiting payment',
              [OrderStatusEnum.CANCELED]: 'Canceled',
              [OrderStatusEnum.CONFIRMED]: 'Confirmed',
              [OrderStatusEnum.PAID]: 'Scheduled availability',
              [OrderStatusEnum.REFUNDED]: 'Refunded',
              [OrderStatusEnum.REQUESTED]: 'Requested',
            },
          },
          pagination: {
            selectItems: '{{value}} items',
          },
          actions: {
            details: 'Order details',
            cancel: 'Cancel order',
            downloadBillet: {
              [PaymentMethodEnum.BILLET]: 'Download billet',
              [PaymentMethodEnum.PIX]: 'Download pix code',
            },
            downloadDebitNote: 'Download debit note',
            downloadInvoice: 'Download invoice',
          },
          errors: {
            cancelError: 'Order cancel error',
          },
          success: {
            cancelOrder: 'Order successfully canceled',
          },
        },
        modalPreOrder: {
          creditType: {
            title: 'What type of benefit is the order for?',
            description:
              'You have people who are assigned two types of benefits',
            content: {
              title:
                'Choose which type of benefit you would like to apply for and proceed.',
              options: [
                {
                  title: 'Standard benefit',
                  description:
                    'People receive the full amount set for the benefit, and it accumulates with the remaining balance deposited last month. Can be paid by billet, pix or Flash Cash',
                },
                {
                  title: 'Benefit with smart recharge',
                  description:
                    'People receive a deposit that respects the balance limit defined for the benefit, and you define the date and time for the balance renewal. Can only be paid with Flash Cash',
                },
              ],
            },
          },
          orderMethod: {
            title: 'How do you want to make your benefit order?',
            description:
              'Benefit orders can be made individually, by group or in a spreadsheet.',
            content: {
              title: 'Choose the benefit order method',
              options: [
                {
                  title: 'Order by selection on the platform',
                  description:
                    'Place the order by selecting people or groups previously registered with Flash',
                },
                {
                  title: 'Spreadsheet',
                  description:
                    'Usefull for orders with a large number of people, by uploading a file with our default spreadsheet format',
                },
                {
                  title: 'CustomSpreadsheet',
                  description:
                    'Usefull for orders with a large number of people, by uploading a spreadsheet customized by your company',
                },
              ],
            },
          },
          steps: ['Benefit type', 'Order method'],
          continueButton: 'Continue',
          backButton: 'Back',
          cancelButton: 'Cancel',
        },
      },
      buttonText: 'Place new order',
      failedToFetchMessage: 'Error when fetching for orders',
    },
    orderDetails: {
      headerPage: {
        title: 'Order details {{orderId}}',
        subtitle: 'Deposits',
      },
      breadcrumbLabel: 'Order Details #{{code}}',
      content: {
        headerContent: {
          title: 'Company benefits requests',
          textTag: 'itens',
        },
        summary: {
          title: 'Order #{{code}}',
          created: 'Created at',
          creditDate: 'Credits availability',
          paymentMethod: 'Payment method',
          total: 'Order total amount',
          paymentMethodTypes: {
            [PaymentMethodEnum.BILLET]: 'Billet',
            [PaymentMethodEnum.PIX]: 'Pix',
            [PaymentMethodEnum.FLASH_CASH]: 'Flash Cash',
          },
          amount: {
            value: '{{value, currency(BRL)}}',
          },
          fees: 'Fees',
          description: 'Additional billet description',
        },
        emptyState: {
          title: "Sorry, we didn't find your search",
          subtitle: 'Check that the information is correct and try again',
        },
        table: {
          columns: {
            employeeDocument: 'Document',
            employeeName: 'Person',
            benefitName: 'Person Benefit',
            value: 'Value',
            status: 'Status',
            creditDate: 'Credit date',
          },
          cells: {
            status: {
              [DepositStatusEnum.SCHEDULED]: {
                title: 'Scheduled availability',
                style: 'gray',
              },
              [DepositStatusEnum.TRANSFERRED]: {
                title: 'Available',
                style: 'green',
              },
              [DepositStatusEnum.CANCELED]: {
                title: 'Canceled',
                style: 'yellow',
              },
              [DepositStatusEnum.REFUNDED]: {
                title: 'Refund',
                style: 'pink',
              },
              [DepositStatusEnum.CREATED]: {
                title: 'Awaiting payment',
                style: 'gray',
              },
              [DepositStatusEnum.WAITING_TO_BE_CALCULATED]: {
                title: 'Scheduled (smart recharge)',
                style: 'gray',
              },
            },
            value: '{{value, currency(BRL)}}',
          },
          pagination: {
            selectItems: '{{value}} itens',
          },
          actions: {
            refund: 'Benefit Refund',
            cancel: `Cancel the person's deposit`,
          },
          errors: {
            cancelError: 'Error cancelling deposit',
          },
          success: {
            cancelDeposit: 'Deposit successfully cancelled',
          },
        },
        searchBar: 'Search by the person name or document',
      },
      failedToFetchMessage: 'Error when fetching for orders',
    },
    refund: {
      title: 'Benefit Reversal',
      breadcrumbLabel: {
        order: 'Orders',
        orderDetails: 'Order Details #{{code}}',
        refund: 'Benefit Reversal',
      },
      personDepositDetails: {
        title: 'Personal and Deposit Details',
        name: 'Full Name',
        cpf: 'CPF',
        deposit: 'Deposit',
        amount: 'Amount Deposited in R$',
        refundAmount: 'Refund Amount',
      },
      steps: {
        usageRules: {
          title: 'Usage Rules',
          subTitle: 'Allows you to reverse benefit deposits provided to teams',
          content: {
            title: 'Usage Rules',
            description:
              'Check the necessary information for the reversal process',
            confirmedCode: 'Confirmed code!',
            permission: {
              title: 'Permission to Use',
              text: 'The administrator needs permission to place Flash Cash orders',
            },
            remainingBalance: {
              title: 'Remaining Balance',
              text: 'Reversal does not guarantee the full return of funds deposited by the company',
            },
            permittedTime: {
              title: 'Permitted Time',
              text: 'Reversals can only be made on business days, from {{initialHour}}am to {{finalHour}}pm in Brasília time zone',
            },
          },
          modalWarn: {
            title: 'Unable to Process Deposit Refund',
            description:
              'You are outside the allowed day or time for processing refunds through the platform. If you have any questions, please contact our Help Center.',
          },
          continueButtonText: 'Continue',
        },
        reviewDetailsAndConfirm: {
          title: 'Review Details and Confirm',
          subTitle:
            'Check the information before confirming, this action cannot be undone after reversal is completed',
          content: {
            title: 'Reversal Information',
            declaration:
              'In my capacity as an authorized representative, I declare, for all legal purposes, that the request for a refund of the indicated amount occurred due to operational and/or manual error at the time of loading the benefit card of the {{companyName}} employee',
          },
          continueButtonText: 'Confirm Reversal',
          modals: {
            insufficientBalance: {
              title: 'Insufficient balance in deposit',
              description:
                'It is not possible to process the refund as there is no available balance in the selected deposit.',
            },
            errorProcessing: {
              title: 'Unable to process refund',
              description:
                'An error occurred while processing your refund request. If you have any questions, please contact our Help Center.',
            },
          },
        },
        reversalCompleted: {
          title: 'Reversal Completed',
          subTitle:
            'Information about the reversed deposit has been shared with other company administrators',
          content: {
            title: 'Reversal Completed!',
            description: 'See details in the Flash Cash transaction area',
          },
          continueButtonText: 'Finish',
          flashCashButtonText: 'Open Flash Cash',
        },
        tag: 'We do not guarantee full reimbursement of the amounts',
      },
      helpText: 'If you still have questions, please consult our',
      helpCenterText: 'Help Center',
      cancelText: 'Cancel',
    },
    newOrder: {
      title: 'Order by selection',
      titleSpreadsheet: 'Order by Flash spreadsheet',
      steps: {
        flashSpreadsheet: {
          title: 'Send the data',
          description:
            'Send the data of the employees and the benefits through the spreadsheet',
        },
        employeeSelection: 'Select the people',
        paymentSetup: 'Define payment, availability, and description',
        reviewDetails: 'Review the details and confirm',
        orderCompleted: 'Order completed',
      },
      buttons: {
        back: 'Back',
        cancel: 'Cancel',
        next: 'Continue',
        completeOrder: 'Complete order',
        complete: 'Complete',
      },
      warnings: {
        selectEmployee: 'You need to select people to proceed with your order.',
        lowDepositAmount: {
          title:
            'It is not possible to order deposits with an amount below R$2.00',
          description:
            'One or more people have benefit amounts below R$2.00 attributed to them. Please remove people from the order or change their assignments.',
        },
      },
      breadcrumb: {
        orders: 'Orders',
        makeOrder: 'Place order',
      },
      flashSpreadsheet: {
        download: 'Flash spreadsheet template in XLSX',
        description: 'Use the Flash spreadsheet template to apply for benefits',
        accordion: {
          title: 'How to format the file for submission?',
          description:
            'Check what data is requested in the spreadsheet template and how to fill it out.',
          instructions: {
            company: {
              title: 'Company CNPJ',
              description: [
                'It is important that the column is formatted as "Text" so that it does not hide characters in CNPJs that start with zero;',
                'The use of periods and hyphens is optional;',
                'This field helps to locate which company within the group the person is registered with.',
              ],
              tag: 'Mandatory',
            },
            document: {
              title: 'CPF',
              description: [
                'It is important that the column is formatted as "Text" so that it does not hide characters in CPFs that start with zero;',
                'The use of periods and hyphens is optional.',
              ],
              tag: 'Mandatory',
            },
            benefitAmount: {
              title: 'Benefit amounts',
              description: [
                'It is important that the column is formatted as "Value" to avoid errors with cents;',
                'Fill in the columns individually for each company benefit;',
                'If the benefit is configured per day, fill in the value considering the number of days the request should take into account.',
              ],
              tag: 'Mandatory',
            },
            name: {
              title: 'Full name',
              description: [],
              tag: 'Only for registering new people',
            },
            email: {
              title: 'Registration email',
              description: [
                'We will send the first access invitation to the Flash platform and app to this email.',
                'If you do not know the email, fill in the phone number so that the invitation is sent via SMS.',
                'Make sure to include the user’s name followed by the "@" symbol and the email provider’s name (e.g., "username@provider.com").',
              ],
              tag: 'Only for registering new people',
            },
            phone: {
              title: 'Phone number',
              description: [
                'Mandatory if no email is available;',
                'If you do not know the person’s email, filling out this field becomes mandatory so that the first access invitation is sent via SMS.',
                'It is necessary to include the area code.',
                'The use of parentheses and hyphens is optional.',
                'The country code (e.g., "+55") should not be filled in.',
              ],
              tag: 'Only for registering new people',
            },
          },
        },
        addEmployees: {
          title: 'Do you want to register new employees?',
          description:
            'If your spreadsheet includes employees who are not yet registered on the Flash platform, it is necessary to indicate below so they can be registered, and the benefits request can be processed for them.',
          firstCard: {
            title: 'Only request benefits',
            description:
              'Select this option if all the employees in your spreadsheet are already registered.',
          },
          secondCard: {
            title: 'Register and request benefits',
            description:
              'Select this option if your spreadsheet includes one or more unregistered employees.',
          },
        },
        uploadFile: {
          title: 'Upload your file',
          description: 'With the file ready, simply attach it below:',
          uploadText: 'Upload your file',
        },
      },
      employeeSelection: {
        title: 'Select the people who will be part of your order',
        subtitle: 'Always check the order summary to track your selections',
        search: {
          label: "Search by the person's name or CPF",
        },
        editGroup: {
          text: 'Edit groups',
          tooltip: 'Takes the page to modify people’s groups',
        },
        tag: {
          selectedEmployee:
            '{{selectedEmployee}} of {{totalEmployee}} selected',
        },
        linkButton: {
          changeBenefitValues: 'Change benefit values',
        },
        bulkActions: {
          alterBenefit: 'Change benefit values',
        },
        pagination: {
          count: '{{total}} results',
        },
        table: {
          columns: {
            group: 'Group',
            benefits: 'Group benefits',
            totalAmount: 'Amount in R$',
            actions: 'Actions',
          },
          noBenefits: 'No benefits',
          actionCell: {
            tooltip: 'Open benefit assignment',
          },
          groupNameCell: {
            person: 'person',
            people: 'people',
          },
        },
        simpleAssignmentEmployeeModal: {
          group: {
            title: 'Change benefit values for selected people',
            description:
              'Select the benefits you want to change and set a new value for them.',
            label: 'Set value',
            toastSuccess: {
              client: {
                title:
                  'Benefit values successfully updated for {{total}} people!',
                description:
                  'The benefit values for this request for the selected people were successfully updated.',
              },
              server: {
                title:
                  'Benefit values successfully updated for {{total}} people!',
                description:
                  'From the next requests onward, these values will be the default values for the selected people.',
              },
            },
          },
          employee: {
            title: 'Change benefit values for {{name}}',
            description:
              'Select the benefits you want to change and set a new value for them.',
            toastSuccess: {
              client: {
                title: 'Benefit values successfully updated!',
                description:
                  'The benefit values for {{name}} for this request were successfully updated.',
              },
              server: {
                title: 'Benefit values successfully updated!',
                description:
                  'From the next requests onward, these values will be the default values for {{name}}.',
              },
            },
          },
          adoptNewValues: {
            title: 'Adopt new values for future requests',
            description:
              'The new values will override the original assignment, and from the next requests onward, they will be the default values for the person.',
          },
          day: 'day',
          month: 'month',
          toastError: {
            title: 'We encountered an error while changing the benefit values',
            description: 'Please try again later, or contact our Help Center.',
          },
          btnCancel: 'Cancel',
          btnConfirm: 'Change benefit values',
        },
      },
      orderCompletion: {
        navigateOrderDeposits: 'View order deposits',
        generateReport: 'Generate order report',
      },
      orderSummary: {
        title: 'Order summary',
        noSelection:
          'Once you start selecting people, a preview of your order will appear here.',
        benefitsTitle: 'Flash Benefits',
        totalPartial: {
          title: 'Partial total',
          tooltip:
            'Final amount will be displayed in the order summary stage, considering possible discounts and fees',
        },
        creditDays: {
          label: 'Calculate value for {{creditDays}} days',
          btnEdit: 'Edit',
          title:
            'Edit the calculation of benefits with a balance defined by day',
          description:
            'Benefits in your order that will have their calculation updated:',
          toastWarning: 'Please enter the number of days for the calculation.',
          inputLabel: 'Number of days',
          btnCancel: 'Cancel',
          btnSave: 'Save',
        },
        noEmployeeAssign: {
          title: 'Employees without assignment selected',
          description:
            'You selected {{employees}} employees without benefit assigned.',
          btnRemove: 'Remove selection',
          toastSuccess:
            'Employees without assign benefits were removed from the order.',
        },
      },
    },
  },
}

export default translations
