import { Button, Icons, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { Box } from 'src/components/box'

import { useProcessingOrderStatus } from '../../hooks/use-processing-order-status'
import { StatusType } from '../processing-status'
import { StatusCard } from '../status-card'

export function StatusProcessed({ processedLines }: StatusType) {
  const { droppedFile, navigateToPaymentSetup } = useProcessingOrderStatus()

  return (
    <StatusCard
      iconName={'IconCheck'}
      iconColor="success"
      title="Sucesso na importação!"
      description={
        <Box display="flex" flexDirection="row" alignItems="center" gap="xs5">
          <Icons size={16} name="IconCircleCheck" />
          <Typography variant="body4">
            {processedLines} pedidos tiveram suas informações processadas
          </Typography>
        </Box>
      }
      titleResult={droppedFile?.name || ''}
      descriptionResult={`${processedLines} de ${processedLines} pessoas importadas`}
      progress={100}
      actionsButtons={[
        <Button
          key="continue"
          variant="primary"
          size="large"
          onClick={navigateToPaymentSetup}
        >
          Continuar para depósitos e pagamento <Icons name="IconArrowRight" />
        </Button>,
      ]}
    />
  )
}
