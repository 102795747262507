import { Tooltip, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { BenefitTypeEnum } from 'src/enums/benefitTypeEnum'
import { formatCurrency } from 'src/utils/mask'

import { GroupedBenefit } from '../../utils/group-benefits'
import {
  BenefitItemEmojiStyled,
  BenefitItemWrapperStyled,
  BenefitsContainerStyled,
  BenefitsListWrapperStyled,
} from './styles'

type BenefitsProps = {
  benefits: GroupedBenefit[]
  benefitType: BenefitTypeEnum
}

type BenefitItemProps = {
  benefit: GroupedBenefit
}

const benefitTypeLabel = {
  [BenefitTypeEnum.PLASTIC]: 'Benefícios no cartão',
  [BenefitTypeEnum.VIRTUAL]: 'Benefícios virtuais',
}

function BenefitItem({ benefit }: BenefitItemProps) {
  const { name, emoji, amount } = benefit

  return (
    <BenefitItemWrapperStyled>
      <Tooltip title={name} arrow placement="left">
        <div>
          <BenefitItemEmojiStyled imgSrc={emoji} width={16} />

          <Typography variant="body4" color="neutral.30">
            {name}
          </Typography>
        </div>
      </Tooltip>

      <Typography variant="body4" color="neutral.30" weight={700}>
        {formatCurrency(amount)}
      </Typography>
    </BenefitItemWrapperStyled>
  )
}

export function Benefits({ benefits, benefitType }: BenefitsProps) {
  return (
    <BenefitsContainerStyled>
      <Typography variant="body4" weight={700} color="neutral.30">
        {benefitTypeLabel[benefitType]}
      </Typography>
      <BenefitsListWrapperStyled>
        {benefits.map((benefit, key) => (
          <BenefitItem key={key} benefit={benefit} />
        ))}
      </BenefitsListWrapperStyled>
    </BenefitsContainerStyled>
  )
}
