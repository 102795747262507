import { useEffect, useMemo } from 'react'
import { trpc } from 'src/api/client'

import { useNewOrderStore } from '../../../store/use-new-order-store'
import { filterEmployeesByCriteria } from '../utils/filter-employees-by-criteria'
import { searchEmployee } from '../utils/search-employee'

export function useEmployeeData(searchTerm: string) {
  const {
    employees,
    employeesFilter,
    isEmployeesInitialized,
    setEmployees,
    markEmployeesAsInitialized,
  } = useNewOrderStore()

  const { data: employeesData = [], isLoading } =
    trpc.simpleAssignment.getEmployees.useQuery()

  const hasEmployees = useMemo(
    () => employeesData.length > 0,
    [employeesData.length],
  )

  const filteredEmployees = useMemo(() => {
    const employeesMatchingSearch = searchTerm
      ? searchEmployee(employees, searchTerm)
      : employees

    return filterEmployeesByCriteria(employeesMatchingSearch, employeesFilter)
  }, [employees, employeesFilter, searchTerm])

  const activeFiltersCount = useMemo(
    () =>
      Object.values(employeesFilter).filter(
        (filterValues) => filterValues.length > 0,
      ).length,
    [employeesFilter],
  )

  useEffect(() => {
    if (employeesData.length && !isEmployeesInitialized) {
      setEmployees(employeesData)
      markEmployeesAsInitialized()
    }
  }, [
    employeesData,
    isEmployeesInitialized,
    markEmployeesAsInitialized,
    setEmployees,
  ])

  return {
    employees,
    employeesData,
    filteredEmployees,
    isLoading,
    isEmployeesInitialized,
    activeFiltersCount,
    hasEmployees,
    setEmployees,
    markEmployeesAsInitialized,
  }
}
