import { Tooltip, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { t } from 'i18next'
import { PaymentMethodEnum } from 'src/enums/paymentMethodEnum'

import { IPaymentMethod, PaymentMethod } from './payment-method'
import { PaymentMethodContainer } from './styles'

interface IPaymentMethodsProps {
  value: PaymentMethodEnum | undefined
  onChange: (_value: PaymentMethodEnum) => void
  paymentMethods: IPaymentMethod[]
  children?: React.ReactNode
}

const PaymentMethods: React.FC<IPaymentMethodsProps> = ({
  paymentMethods,
  onChange,
  value,
  children,
}) => {
  return (
    <PaymentMethodContainer>
      <Typography variant={'headline8'} color="neutral.20">
        {t('order.paymentMethods.title')}
      </Typography>
      {paymentMethods.map((method: IPaymentMethod) => {
        const paymentMethod = (
          <PaymentMethod
            {...method}
            key={method.methodKey}
            onClick={onChange}
            isSelected={method.methodKey === value}
          />
        )

        if (method.methodKey === PaymentMethodEnum.PIX && method.disabled) {
          return (
            <Tooltip
              key={method.methodKey}
              title="Não é possível realizar pedidos de benefícios virtuais via Pix."
            >
              <div>{paymentMethod}</div>
            </Tooltip>
          )
        }

        return paymentMethod
      })}

      {children}
    </PaymentMethodContainer>
  )
}

export default PaymentMethods
