import { Loader } from '@flash-tecnologia/hros-web-ui-v2'
import { lazy, Suspense } from 'react'
import { Route } from 'react-router-dom'
import NewOrderPage from 'src/domain/orders/pages/new-order-page'

import { OrdersGuardRoute } from './orders-guard-route'
const OrdersPage = lazy(() => import('src/domain/orders/pages/orders-page'))
const OrderDetailsPage = lazy(
  () => import('src/domain/orders/pages/[:orderId]'),
)
const RefundPage = lazy(() => import('src/domain/orders/pages/refund-page'))

export const OrdersRouter = [
  <Route element={<OrdersGuardRoute />} key="OrdersGuard">
    <Route
      path="orders"
      index
      element={
        <Suspense fallback={<Loader size="small" variant="primary" />}>
          <OrdersPage />
        </Suspense>
      }
      key="OrdersPage"
    />
    <Route
      path="orders/new"
      index
      element={<NewOrderPage />}
      key="NewOrderPage"
    />
    <Route
      path="orders/:orderId"
      index
      element={
        <Suspense fallback={<Loader size="small" variant="primary" />}>
          <OrderDetailsPage />
        </Suspense>
      }
      key="OrderDetailsPage"
    />
    <Route
      path="orders/:orderId/deposits/:depositId/refund"
      index
      element={
        <Suspense fallback={<Loader size="small" variant="primary" />}>
          <RefundPage />
        </Suspense>
      }
      key="RefundPage"
    />
  </Route>,
]
