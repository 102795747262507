import {
  ProgressBar,
  ShapeIconProps,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2'
import { ReactNode } from 'react'
import { Box } from 'src/components/box'
import { DownloadButton } from 'src/components/download-button'

import { useProcessingOrderStatus } from '../../hooks/use-processing-order-status'
import {
  ActionsContainerStyled,
  DescriptionContainerStyled,
  DownloadContainerStyled,
  ProgressContainerStyled,
  ShapeIconStyled,
  TitleDescriptionContainerStyled,
} from './styles'

type ProcessingStatusType = {
  iconName: ShapeIconProps['name']
  iconColor: ShapeIconProps['variant']
  title: string
  description?: ReactNode
  titleResult: string
  descriptionResult: string
  errorsFileUrl?: string
  progress?: number
  actionsButtons: React.ReactNode
}

export function StatusCard({
  iconName,
  iconColor,
  title,
  description,
  titleResult,
  progress,
  descriptionResult,
  errorsFileUrl,
  actionsButtons,
}: ProcessingStatusType) {
  const { downloadErrorsFile } = useProcessingOrderStatus()

  return (
    <Box>
      <TitleDescriptionContainerStyled>
        <ShapeIconStyled
          size={48}
          variant={iconColor}
          stroke={iconColor}
          strokeWidth="2"
          name={iconName}
        />

        <Typography variant="headline8" weight={700} color="neutral.30">
          {title}
        </Typography>
        <DescriptionContainerStyled>
          <Typography variant="body4" color="neutral.50">
            {description}
          </Typography>
        </DescriptionContainerStyled>
      </TitleDescriptionContainerStyled>

      <Box display="flex" flexDirection="column" gap="xs5">
        <Typography variant="body3" weight={700} color="neutral.20">
          {titleResult}
        </Typography>
        <Typography variant="body3" color="neutral.50">
          {descriptionResult}
        </Typography>
      </Box>

      {progress !== undefined && (
        <ProgressContainerStyled>
          <ProgressBar
            variant={progress < 100 ? 'default' : 'success'}
            progress={progress}
          />
        </ProgressContainerStyled>
      )}
      {errorsFileUrl && (
        <DownloadContainerStyled>
          <DownloadButton
            onClick={downloadErrorsFile}
            iconLeft="IconFileInvoice"
            iconLeftColor="default"
            iconRight="IconDownload"
            iconRightColor="pink"
            label="Lista-de-erros.txt"
            width="494px"
          />
        </DownloadContainerStyled>
      )}
      <ActionsContainerStyled>{actionsButtons}</ActionsContainerStyled>
    </Box>
  )
}
