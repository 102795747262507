import { IconsProps } from '@flash-tecnologia/hros-web-ui-v2'
import { Hidden } from '@mui/material'
import { RadioCard } from 'src/components/radio-card'
import { RadioCardTextTypeEnum } from 'src/components/radio-card/radio-card-text/radio-card-text'
import { PaymentMethodEnum } from 'src/enums/paymentMethodEnum'

import { PaymentMethodIcon } from './payment-method-icon'
import { TextBox } from './styles'

export interface IPaymentMethod {
  disabled: boolean
  title: string
  description: string
  additionalDetails?: string
  icon: IconsProps['name']
  methodKey: PaymentMethodEnum
}

export interface IPaymentMethodProps extends IPaymentMethod {
  onClick: (_value: PaymentMethodEnum) => void
  isSelected?: boolean
}

export const PaymentMethod: React.FC<IPaymentMethodProps> = ({
  additionalDetails,
  description,
  disabled,
  isSelected = false,
  onClick,
  methodKey,
  title,
}) => {
  return (
    <RadioCard.Root
      disabled={disabled}
      checked={isSelected}
      onClick={() => onClick(methodKey)}
    >
      <RadioCard.Radio checked={isSelected} disabled={disabled} />
      <Hidden lgDown>
        <PaymentMethodIcon paymentMethod={methodKey} disabled={disabled} />
      </Hidden>
      <TextBox>
        <RadioCard.Text
          checked={isSelected}
          disabled={disabled}
          text={title}
          textVariant={'body3'}
          type={RadioCardTextTypeEnum.TITLE}
        />
        <RadioCard.Text
          checked={isSelected}
          disabled={disabled}
          text={description}
          textVariant={'body4'}
        />
        {additionalDetails && (
          <RadioCard.Text
            checked={isSelected}
            disabled={disabled}
            text={additionalDetails}
            textVariant={'body4'}
          />
        )}
      </TextBox>
    </RadioCard.Root>
  )
}
