import styled from 'styled-components'

export const EmployeeSelectionContainerStyled = styled.div`
  display: grid;
  grid-template-columns: calc(100% - 30%) auto;
  gap: ${({ theme }) => theme.spacings.xs};
`

export const ContentContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
`

export const RadioCardsContainerStyled = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${({ theme }) => theme.spacings.xs};

  margin-bottom: ${({ theme }) => theme.spacings.m};
`

export const HeaderContainerStyled = styled.div<{ hasMarginBottom?: boolean }>`
  display: flex;
  flex-direction: column;

  gap: ${({ theme }) => theme.spacings.xs5};

  margin-bottom: ${({ theme, hasMarginBottom }) =>
    hasMarginBottom ? theme.spacings.xs : '0px'};
`

export const RadioCardsGridStyled = styled.div`
  display: flex;
  align-items: center;

  gap: ${({ theme }) => theme.spacings.xs};
`
