import { BoxAdornment } from '@flash-tecnologia/hros-web-ui-v2'
import styled from 'styled-components'

export const ContentStyled = styled.div`
  margin-bottom: ${({ theme }) => theme.spacings.xs2};
`
export const BoxAdornmentStyled = styled(BoxAdornment)`
  margin-bottom: ${({ theme }) => theme.spacings.xs};

  > div {
    text-align: start;
  }
`
