import { useMemo } from 'react'
import { CreditTypeEnum } from 'src/components/orders/components/pre-order-checkout-modal/content/credit-type'

import { filterByCreditType } from '../../../steps/payment-setup/utils/filter-by-credit-type'
import { useNewOrderStore } from '../../../store/use-new-order-store'
import { groupBenefits } from '../utils/group-benefits'

export function useOrderSummary() {
  const { selectedEmployees, isTopupCredit, creditDays } = useNewOrderStore()

  const creditType = isTopupCredit
    ? CreditTypeEnum.TOPUP
    : CreditTypeEnum.ACCUMULATIVE

  const hasEmployeeSelected = useMemo(
    () => selectedEmployees.length > 0,
    [selectedEmployees.length],
  )

  const groupedBenefits = useMemo(
    () =>
      groupBenefits(
        filterByCreditType(selectedEmployees, creditType),
        creditDays,
      ),
    [creditDays, creditType, selectedEmployees],
  )

  const total = useMemo(
    () => groupedBenefits.reduce((acc, benefit) => acc + benefit.amount, 0),
    [groupedBenefits],
  )

  return {
    hasEmployeeSelected,
    groupedBenefits,
    total,
  }
}
