import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { Box } from 'src/components/box'
import styled from 'styled-components'

export const TitleStyled = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacings.m};
`

export const GridContainerStyled = styled(Box)`
  display: grid;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacings.xs};
  grid-template-columns: calc(100% - 75%) auto;
`
