import { t } from 'i18next'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { trpc } from 'src/api/client'
import { useOrder } from 'src/domain/checkout/context/order-context'
import { PaymentMethodEnum } from 'src/enums/paymentMethodEnum'
import { useEventTracking } from 'src/utils/hooks/useEventTracking'
import { openNewTab } from 'src/utils/openNewTab'
import { redirectToURL } from 'src/utils/redirectToURL'

import { useNewOrderStore } from '../../../store/use-new-order-store'
import { isBalanceInsufficient } from '../../payment-setup/utils/is-balance-insuficcient'

export function useOrderCompletion() {
  const { trackEvent, trackPage } = useEventTracking()

  const {
    order,
    isTopupCredit,
    orderSummary,
    flashCashBalances,
    setContinueButtonOptions,
    resetStore,
    setOrder,
  } = useNewOrderStore()
  const { setPixCode: setOrderOld } = useOrder()
  const [showFlashCashTopUpModal, setShowFlashCashTopUpModal] = useState(false)
  const [pixCode, setPixCode] = useState('')

  const inputGetPixCode = { id: '' }
  const navigate = useNavigate()
  const utils = trpc.useUtils()

  const refetchOrders = useRef(utils.benefitOrders.getOrders.refetch)
  const refetchDeposits = useRef(utils.benefitOrders.getDeposits.refetch)

  const cutOffDate = useMemo(
    () => order?.cutoffDate?.format('DD/MM'),
    [order?.cutoffDate],
  )

  const handleOpenNewTab = useCallback((_path: string) => {
    const url = `${window.location.origin}/${_path}`

    openNewTab(url)
  }, [])

  const handleCloseModal = useCallback(() => {
    setShowFlashCashTopUpModal(false)

    redirectToURL('/benefits/dashboard/company/employees')
  }, [])

  const redirectToBalancePage = useCallback(() => {
    redirectToURL('/benefits/dashboard/company/balance')
  }, [])

  const { refetch: getPixCode, isFetching: isGettingPixCode } =
    trpc.financeServer.getBillPixCode.useQuery(inputGetPixCode, {
      enabled: false,
      onSuccess: (data) => {
        if (data) {
          setOrder({ pixCode: data })
          setPixCode(data)
          setOrderOld(data)
        }
      },
    })

  useEffect(() => {
    if (order?.paymentMethod === PaymentMethodEnum.PIX && !pixCode) {
      inputGetPixCode.id = order?.id ?? ''
      getPixCode()
    }
  }, [order, pixCode])

  const getBillPixCode = (orderId: string) => {
    inputGetPixCode.id = orderId
    getPixCode()
  }

  const handleComplete = useCallback(async () => {
    trackEvent({
      name: 'FlashOS_BenefitOrders_Checkout_Confirmation_Complete_Click',
    })

    const isTopupRequired =
      isTopupCredit &&
      flashCashBalances.length > 0 &&
      flashCashBalances.some((balance) =>
        isBalanceInsufficient(balance, orderSummary?.totalAmount ?? 0),
      )

    if (isTopupRequired) {
      setShowFlashCashTopUpModal(true)
    } else {
      refetchOrders.current()
      refetchDeposits.current()

      resetStore()

      navigate('/orders', { replace: true })
    }
  }, [
    flashCashBalances,
    isTopupCredit,
    navigate,
    orderSummary?.totalAmount,
    resetStore,
    trackEvent,
    refetchOrders,
    refetchDeposits,
  ])

  useEffect(() => {
    trackPage({ name: 'FlashOS_BenefitOrders_Checkout_Confirmation_View' })

    setContinueButtonOptions({
      text: t('newOrder.buttons.complete'),
      onClick: handleComplete,
      isLoading: false,
      showIcon: false,
    })
  }, [handleComplete, setContinueButtonOptions, trackPage])

  return {
    order,
    orderSummary,
    showFlashCashTopUpModal,
    cutOffDate,
    handleOpenNewTab,
    handleCloseModal,
    redirectToBalancePage,
    isGettingPixCode,
    pixCode,
    getBillPixCode,
  }
}
