import { Button, Icons } from '@flash-tecnologia/hros-web-ui-v2'

import { useProcessingOrderStatus } from '../../hooks/use-processing-order-status'
import { StatusType } from '../processing-status'
import { StatusCard } from '../status-card'

export function StatusPartiallyProcessed({ errorsFileUrl }: StatusType) {
  const { navigateToPaymentSetup, makeNewSpreadsheetOrder } =
    useProcessingOrderStatus()

  return (
    <StatusCard
      iconName={'IconAlertTriangle'}
      iconColor="error"
      title="Processamento finalizado"
      titleResult="Alguns pedidos de benefícios não puderam ser importados."
      descriptionResult="Confira os erros no arquivo abaixo. Você pode refazer a importação dessas informações, ou continuar o pedido apenas com as informações que foram importadas com sucesso."
      errorsFileUrl={errorsFileUrl}
      actionsButtons={[
        <Button
          key="back-to-new-import"
          variant="secondary"
          size="large"
          onClick={makeNewSpreadsheetOrder}
        >
          Voltar e fazer nova importação <Icons name="IconArrowBack" />
        </Button>,
        <Button
          key="continue"
          variant="primary"
          size="large"
          onClick={navigateToPaymentSetup}
        >
          Continuar com os pedidos processados <Icons name="IconArrowRight" />
        </Button>,
      ]}
    />
  )
}
