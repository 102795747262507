import { Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from 'src/components/grid'
import { useTheme } from 'styled-components'

import { OrderMethodRadioCard } from '../order-method-radio-card'
import { ModalContentStyled } from './styles'

export enum OrderMethodEnum {
  PLATFORM = 'platform',
  SPREADSHEET = 'spreadsheet',
  CUSTOM_SPREADSHEET = 'customSpreadsheet',
}

type OrderMethodProps = {
  selectedValue?: string
  onSelect: (orderMethod: OrderMethodEnum) => void
}

export const OrderMethod = ({ selectedValue, onSelect }: OrderMethodProps) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const options = useMemo(
    () => [
      {
        value: OrderMethodEnum.PLATFORM,
        titleKey:
          'orders.content.modalPreOrder.orderMethod.content.options.0.title',
        descriptionKey:
          'orders.content.modalPreOrder.orderMethod.content.options.0.description',
        icon: 'IconUsers' as const,
      },
      {
        value: OrderMethodEnum.SPREADSHEET,
        titleKey:
          'orders.content.modalPreOrder.orderMethod.content.options.1.title',
        descriptionKey:
          'orders.content.modalPreOrder.orderMethod.content.options.1.description',
        icon: 'IconTable' as const,
      },
      {
        value: OrderMethodEnum.CUSTOM_SPREADSHEET,
        titleKey:
          'orders.content.modalPreOrder.orderMethod.content.options.2.title',
        descriptionKey:
          'orders.content.modalPreOrder.orderMethod.content.options.2.description',
        icon: 'IconTable' as const,
      },
    ],
    [],
  )

  return (
    <ModalContentStyled>
      <Grid.Container flexDirection={'column'} gap={theme.spacings.xs}>
        <Typography variant="body3" color="neutral.20" weight={700}>
          {t('orders.content.modalPreOrder.orderMethod.content.title')}
        </Typography>

        {options.map((option) => (
          <OrderMethodRadioCard
            key={option.value}
            title={t(option.titleKey)}
            description={t(option.descriptionKey)}
            icon={option.icon}
            selected={selectedValue === option.value}
            onSelect={() => onSelect(option.value)}
          />
        ))}
      </Grid.Container>
    </ModalContentStyled>
  )
}
