import { dayjs, IconTypes } from '@flash-tecnologia/hros-web-ui-v2'
import { GetOrderSummaryResponse } from 'backend/src/services'
import { PaymentMethodEnum } from 'src/enums/paymentMethodEnum'
import { processEnv } from 'src/utils/env'
import { create } from 'zustand'

import { Employee } from '../steps/employee-selection/types'

export const CREDIT_DAYS_DEFAULT = 30

export enum Step {
  EMPLOYEE_SELECTION = 0,
  PAYMENT_SETUP = 1,
  REVIEW_DETAILS = 2,
  ORDER_COMPLETED = 3,
}

type Order = {
  id?: string
  paymentMethod?: PaymentMethodEnum
  depositDate?: dayjs.Dayjs
  dueDate?: dayjs.Dayjs
  cutoffDate?: dayjs.Dayjs
  receiptDescription?: string
  pixCode?: string
}

export type FlashCashBalance = {
  label: 'plastic' | 'virtual'
  value: number
}

export type StepNavigationButtonOptions = {
  text: string
  onClick?: () => void
  isLoading?: boolean
  showIcon?: boolean
  iconName?: IconTypes
  iconPosition?: 'left' | 'right'
  disabled?: boolean
}

type NewOrderStoreState = {
  currentStep: Step
  selectedEmployees: Employee[]
  order: Order | null
  orderSummary: GetOrderSummaryResponse | null
  isTopupCredit: boolean
  continueButtonOptions: StepNavigationButtonOptions | null
  backButtonOptions: StepNavigationButtonOptions | null
  flashCashBalances: FlashCashBalance[] | []
  creditDays: number
}

type NewOrderStoreSetters = {
  setSelectedEmployees: (employees: Employee[]) => void
  setOrder: (order: Partial<Order>) => void
  setOrderSummary: (summary: GetOrderSummaryResponse | null) => void
  resetOrder: () => void
  setContinueButtonOptions: (options: StepNavigationButtonOptions) => void
  setBackButtonOptions: (options: StepNavigationButtonOptions) => void
  nextStep: () => void
  previousStep: () => void
  setFlashCashBalances: (balances: FlashCashBalance[]) => void
  setIsTopupCredit: (isTopup: boolean) => void
  setCreditDays: (days: number) => void
  resetStore: () => void
}

type NewOrderStore = NewOrderStoreState & NewOrderStoreSetters

const initialState: NewOrderStoreState = {
  selectedEmployees: [],
  order: null,
  orderSummary: null,
  continueButtonOptions: null,
  backButtonOptions: null,
  currentStep: Step.EMPLOYEE_SELECTION,
  flashCashBalances: [],
  isTopupCredit: false,
  creditDays: CREDIT_DAYS_DEFAULT,
}

export const useNewOrderStore = create<NewOrderStore>((set) => ({
  ...initialState,
  setSelectedEmployees: (employees) => {
    set({ selectedEmployees: employees })
  },
  setOrder: (order) => {
    set((oldState) => ({ order: { ...oldState.order, ...order } }))
  },
  resetOrder: () => {
    set({ order: initialState.order })
  },
  setOrderSummary: (summary) => {
    set({ orderSummary: summary })
  },
  setContinueButtonOptions: (options) => {
    set({ continueButtonOptions: options })
  },
  setBackButtonOptions: (options) => {
    set({ backButtonOptions: options })
  },
  nextStep: () => {
    const stepMapping = {
      [Step.EMPLOYEE_SELECTION]: Step.PAYMENT_SETUP,
      [Step.PAYMENT_SETUP]: Step.REVIEW_DETAILS,
      [Step.REVIEW_DETAILS]: Step.ORDER_COMPLETED,
      [Step.ORDER_COMPLETED]: Step.ORDER_COMPLETED,
    }

    set((oldState) => ({
      currentStep: stepMapping[oldState.currentStep],
      continueButtonOptions: null,
      backButtonOptions: null,
    }))
  },
  previousStep: () => {
    const stepMapping = {
      [Step.EMPLOYEE_SELECTION]: Step.EMPLOYEE_SELECTION,
      [Step.PAYMENT_SETUP]: Step.EMPLOYEE_SELECTION,
      [Step.REVIEW_DETAILS]: Step.PAYMENT_SETUP,
      [Step.ORDER_COMPLETED]: Step.ORDER_COMPLETED,
    }

    set((oldState) => ({
      currentStep: stepMapping[oldState.currentStep],
      continueButtonOptions: null,
      backButtonOptions: null,
    }))
  },
  setFlashCashBalances: (balances) => {
    set({ flashCashBalances: balances })
  },
  setIsTopupCredit: (isTopup) => {
    set({ isTopupCredit: isTopup })
  },
  setCreditDays(days) {
    set({ creditDays: days })
  },
  resetStore: () => {
    set({ ...initialState })
  },
}))

if (processEnv.BUILD_ENV === 'development') {
  useNewOrderStore.subscribe((state) => {
    console.log('state updated (useNewOrderStore)', state)
  })
}
