import {
  Table as TableUi,
  tableControllers,
  TagProps,
} from '@flash-tecnologia/hros-web-ui-v2'
import { ChangeEvent, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export type TablePagination = {
  pageNumber: number
  pageSize: number
  totalPages?: number
  totalItems?: number
  onPaginationChange: (_: any) => void
}

type TableEmptyState = {
  message?: string
  children?: React.ReactNode
}

export type TableProps<T> = {
  title: string
  tagColor: TagProps['variant']
  loading: boolean
  baseData: T[]
  columns: any[]
  pagination: TablePagination
  options?: any
  search?: {
    onChange: (_: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
    label: string
  }
  emptyState?: TableEmptyState
}
export const Table = <T extends object>(props: TableProps<T>) => {
  const { t } = useTranslation()

  const data = useMemo(
    () => (props.loading ? [] : props.baseData),
    [props.baseData, props.loading],
  )

  const count = useMemo(
    () => props.pagination.totalItems || data.length,
    [data.length, props.pagination.totalItems],
  )

  const countLabel = useMemo(
    () => `${count} ${t('orderDetails.content.headerContent.textTag')}`,
    [count, t],
  )

  const table = tableControllers.useTableColumns<T>({
    options: props.options,
    columns: props.columns,
    data,
    pagination: props.pagination,
    onPaginationChange: props.pagination.onPaginationChange,
    total: count,
  })

  return (
    <TableUi.Root variant="soft">
      <TableUi.Header
        title={props.title}
        tagLabel={countLabel}
        tag={props.tagColor}
      />

      <TableUi.Content>
        {props.search && (
          <TableUi.FilterSearch
            labelSearch={props.search.label}
            onSearch={props.search.onChange}
          />
        )}
        <TableUi.Grid.Root
          loading={props.loading}
          empty={{ message: '', ...props.emptyState }}
        >
          <TableUi.Grid.Header getHeaderGroups={table.getHeaderGroups} />
          {table.rows.map((row) => (
            <TableUi.Grid.Row key={row.id} row={row} />
          ))}
        </TableUi.Grid.Root>

        <TableUi.Pagination
          count={count}
          onPaginationChange={props.pagination.onPaginationChange}
          pagination={props.pagination}
          showItemRange
        />
      </TableUi.Content>
    </TableUi.Root>
  )
}
