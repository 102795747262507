import { Divider, Typography } from '@flash-tecnologia/hros-web-ui-v2'
import { useTranslation } from 'react-i18next'
import { BenefitTypeEnum } from 'src/enums/benefitTypeEnum'

import { SummaryFooter } from './components/footer/footer'
import { SummaryHeader } from './components/header/header'
import { OrderSummary as BenefitSummary } from './components/summary'
import { TotalPartial } from './components/total-partial/total-partial'
import {
  IOrderSummaryBenefitProps,
  IOrderSummaryTotalAmountsProps,
} from './order-summary.interface'
import { BenefitSummaryListStyled, EmojiStyled } from './styles'

export interface IOrderSummaryProps {
  benefits?: IOrderSummaryBenefitProps[]
  totals?: IOrderSummaryTotalAmountsProps[]
  employeesCount: number
  showEmployeesOnClick: () => void
  isTopup?: boolean
  creditDays?: number
  benefitType?: BenefitTypeEnum
}

export const OrderSummary = ({
  benefits,
  totals,
  employeesCount,
  showEmployeesOnClick,
  isTopup = false,
  creditDays,
  benefitType = BenefitTypeEnum.PLASTIC,
}: IOrderSummaryProps) => {
  const { t } = useTranslation()

  const benefitTypeText =
    benefitType === BenefitTypeEnum.PLASTIC
      ? 'Benefícios no cartão'
      : 'Benefícios virtuais'

  return (
    <>
      <SummaryHeader benefitsCount={benefits?.length} />

      <Divider orientation="horizontal" />

      {benefits?.length && (
        <BenefitSummary.Root>
          <Typography variant="body4" color="neutral.30" weight={700}>
            {benefitTypeText}
          </Typography>

          <BenefitSummaryListStyled>
            {benefits.map(({ value, image, label }) => (
              <BenefitSummary.Item key={`benefits-item-${label + value}`}>
                <BenefitSummary.ItemRoot>
                  <EmojiStyled imgSrc={image} width={16} />
                  <Typography variant="body4" color="neutral.30">
                    {label}
                  </Typography>
                </BenefitSummary.ItemRoot>
                <Typography variant="body4" color="neutral.30" weight={700}>
                  {t('order.checkout.orderSummary.benefits.value', {
                    value,
                  })}
                </Typography>
              </BenefitSummary.Item>
            ))}
          </BenefitSummaryListStyled>
        </BenefitSummary.Root>
      )}

      <Divider orientation="horizontal" />

      {totals?.length && (
        <TotalPartial isTopup={isTopup} totalAmount={totals[0].value} />
      )}

      <Divider orientation="horizontal" />

      <SummaryFooter
        employeesCount={employeesCount}
        showEmployeesOnClick={showEmployeesOnClick}
        creditDays={creditDays}
      />
    </>
  )
}
